import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import SidebarData from '../SidebarData.json'
// import secureLocalStorage from 'react-secure-storage'

function sortMenuItems(menuItems: string): string {
  const parsedMenuItems = JSON.parse(menuItems)
  const sortedMenuItems = parsedMenuItems.sort(
    (a: {sortOrder: number}, b: {sortOrder: number}) => a.sortOrder - b.sortOrder
  )
  return JSON.stringify(sortedMenuItems)
}
const SidebarMenuMain = () => {
  const intl = useIntl()
  // const userInfo: any | null = secureLocalStorage.getItem('user_info')

  const {is_admin, is_finance, is_hr, is_itadmin, is_manager} = {
    is_admin: 0,
    is_finance: 0,
    is_hr: 0,
    is_itadmin: 0,
    is_manager: 0,
  }

  const sortedSidebardata3 = sortMenuItems(JSON.stringify(SidebarData))
  const sortedSidebardata3JSON = JSON.parse(sortedSidebardata3)

  const filteredModules = sortedSidebardata3JSON.filter((menuItem: any) => {
    if (menuItem.module === 'LABEL.HR_ADMIN') {
      if (is_admin === 1 || is_hr === 1) {
        return menuItem
      }
    } else if (menuItem.module === 'LABEL.ALL_EMPLOYEES') {
      if (is_admin == 1 || is_itadmin == 1 || is_hr == 1) {
        return menuItem
      } else {
        return null
      }
    } else {
      return menuItem
    }
  })

  return (
    <>
      {filteredModules.map((menuItem: any, index: any) => {
        const subMenuItems = menuItem.menuItems
        if (menuItem.single) {
          return (
            <SidebarMenuItem
              key={menuItem.sortOrder}
              // to={menuItem.path}
              to={`${menuItem.secId || ''}${menuItem.path}`}
              icon={menuItem.icon}
              title={menuItem.module}
              // title={intl.formatMessage({id: menuItem.module})}
            />
          )
        } else if (subMenuItems === null) {
          return (
            <div className='menu-item' key={menuItem.sortOrder}>
              <SidebarMenuItemWithSub
                to='/crafted/pages'
                title={menuItem.module}
                // title={intl.formatMessage({id: menuItem.module})}
                icon={menuItem.icon}
              />
            </div>
          )
        } else {
          const isHrAccessible = menuItem.accessible === 'Admin' && is_hr === 1
          const isFinanceAccessible = menuItem.accessible === 'Finance' && is_finance === 1
          const isManagerAccessible = menuItem.accessible === 'Manager' && is_manager === 1
          const isITAdminAccessible = menuItem.accessible === 'IT Admin' && is_itadmin === 1
          if (
            (menuItem.accessible && isHrAccessible) ||
            (menuItem.accessible && isFinanceAccessible) ||
            (menuItem.accessible && isManagerAccessible) ||
            (menuItem.accessible && isITAdminAccessible) ||
            !menuItem.accessible
          ) {
            return (
              <div key={menuItem.sortOrder} className='d-block py-1'>
                <SidebarMenuItemWithSub
                  to='/crafted/pages'
                  hasChildren={subMenuItems.length > 0}
                  title={menuItem.module}
                  // title={intl.formatMessage({id: menuItem.module})}
                  fontIcon='bi-archive'
                  icon={menuItem.icon}
                >
                  {subMenuItems.map((each: any, index: any) => {
                    return (
                      <SidebarMenuItem
                        key={index}
                        to={`${menuItem.secId || ''}${each.module}`}
                        title={each.name}
                        // title={intl.formatMessage({id: each.name})}
                        hasBullet={true}
                      />
                    )
                  })}
                </SidebarMenuItemWithSub>
              </div>
            )
          }
        }
      })}
    </>
  )
}

export {SidebarMenuMain}
