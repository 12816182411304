import {useEffect, useMemo, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useTable, usePagination} from 'react-table'
import usePageTitle from './custom_hooks/usePageTitle'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../_metronic/helpers'
import {useIntl, FormattedMessage} from 'react-intl'
import usePostApi from './custom_hooks/usePostApi'
import {PageTitle} from '../../_metronic/layout/core'
import AddNewArticleCustom from './AddNewArticleCustom'

const DataTable = ({columns, data, loading, fetchData, refresh, sectionId, sectionData}) => {
  const [inputVal, setInputVal] = useState('')
  const intl = useIntl()
  const [showKM, setShowKM] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [currentStatus, setCurrentStatus] = useState(1)
  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const [sortOrder, setSortOrder] = useState(0)

  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const TitleMatches = each.title_en
            ? each.title_en.toLowerCase().includes(inputVal.toLowerCase())
            : true
          const currentStatusMatches = each.status === currentStatus
          return TitleMatches && currentStatusMatches
        })
      : []
  }, [inputVal, currentStatus, data])

  const sortedData = useMemo(() => {
    if (sortOrder === 1) {
      return [...filteredData].sort((a, b) => a.sort_rank - b.sort_rank)
    } else if (sortOrder === -1) {
      return [...filteredData].sort((a, b) => b.sort_rank - a.sort_rank)
    }
    return filteredData
  }, [filteredData, sortOrder])

  const TableInstance = useTable({columns, data: sortedData}, usePagination)

  const activePage = TableInstance.page
  const activePageCount = TableInstance.pageCount
  const activePageIndex = TableInstance.state.pageIndex
  const activePageSize = TableInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    TableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    TableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset

  const pageOptions = [5, 10, 20, 30, 40, 50]

  const [showCrtModal, setShowCrtModal] = useState(false)

  const candidateAttachments = [
    {
      title: `Manage ${sectionData?.name}`,
      path: sectionData?.path,
      isSeparator: false,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={candidateAttachments}>{sectionData?.title}</PageTitle>
      <AddNewArticleCustom
        show={showCrtModal}
        onClose={() => setShowCrtModal(false)}
        fetchData={fetchData}
        secId={sectionId}
        type={'create'}
        data={{}}
        timeStamp={new Date()}
      />
      <>
        {loading && refresh === false ? (
          <div className='card h-250px'>
            <div className='m-auto d-flex flex-column align-items-center'>
              <div className='spinner-border spinner-primary mr-15'></div>
            </div>
          </div>
        ) : data.length > 0 ? (
          <>
            <div className='d-flex flex-wrap flex-stack '>
              <div className='d-flex'>
                <h1 className='fw-semibold fs-3'>Manage Articles</h1>
              </div>
              <div className='d-flex'>
                <div className='d-flex'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <button className='btn btn-sm btn-light' onClick={() => setShowKM(true)}>
                      <i className='ki-duotone ki-information-3 fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Know More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <p className='fw-normal fs-6 text-gray-600'>
              Seamlessly create, update, and track articles, ensuring no details are overlooked
              during the hiring process.
            </p>

            {refresh ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : (
              <div className='card py-2 px-10 mb-5'>
                <div className='card-header border-0 px-0'>
                  <h3 className='card-title align-items-start flex-column'>
                    <ul className='nav'>
                      <li className='nav-item'>
                        <span
                          className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-success ${
                            currentStatus === 1 ? 'active' : ''
                          } fw-normal fs-6 px-4 me-1`}
                          data-bs-toggle='tab'
                          onClick={() => setCurrentStatus(1)} // Set status to active
                        >
                          Active
                        </span>
                      </li>
                      <li className='nav-item'>
                        <span
                          className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-danger ${
                            currentStatus === 0 ? 'active' : ''
                          } fw-normal fs-6 px-4 me-1`}
                          data-bs-toggle='tab'
                          onClick={() => setCurrentStatus(0)} // Set status to inactive
                        >
                          Inactive
                        </span>
                      </li>
                    </ul>
                  </h3>

                  <div className='d-flex align-items-center'>
                    <div className='d-flex align-items-center position-relative'>
                      <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                      <input
                        type='search'
                        id='kt_filter_search'
                        className='form-control form-control-white form-control-sm w-150px ps-9'
                        placeholder='Search'
                        value={inputVal}
                        onChange={(e) => setInputVal(e.target.value)}
                      />
                    </div>

                    {/* <button
                      className='btn btn-sm btn-light-primary ms-3'
                      onClick={() => setShowCrtModal(true)}
                      data-toggle='tooltip'
                      title='Create job article'
                    >
                      <KTIcon iconName='plus' className='fs-3' />
                      Create
                    </button> */}
                    <Link
                      to={sectionData?.crtPath}
                      className='btn btn-sm btn-light-primary ms-3'
                      onClick={() => setShowCrtModal(true)}
                      data-toggle='tooltip'
                      title='Create job article'
                    >
                      <KTIcon iconName='plus' className='fs-3' />
                      Create
                    </Link>

                    <button
                      className='btn btn-sm btn-light-primary ms-3'
                      onClick={() => fetchData(true)}
                      data-toggle='tooltip'
                      title='Reload articles data'
                    >
                      <i className='ki-solid ki-arrows-circle fs-3'></i>
                    </button>
                  </div>
                </div>

                <div className='mb-0 mb-xl-8 '>
                  <div className='overflow-auto'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle'>
                      <thead>
                        <tr className='fw-semibold text-muted bg-light rounded'>
                          <th className='min-w-150px default-cursor text-start ps-3'>
                            Sort Rank{' '}
                            <i
                              className='las la-sort-amount-down-alt fs-3 text-primary cursor-pointer'
                              data-bs-toggle='tooltip'
                              title='Sort in ascending order'
                              onClick={() => setSortOrder(sortOrder === 1 ? 0 : 1)}
                            ></i>
                            <i
                              className='las la-sort-amount-down text-primary fs-3 cursor-pointer'
                              data-bs-toggle='tooltip'
                              title='Sort in descending order'
                              onClick={() => setSortOrder(sortOrder === -1 ? 0 : -1)}
                            ></i>
                          </th>
                          <th className='min-w-150px default-cursor text-start'>Title (En)</th>
                          <th className='min-w-150px default-cursor text-start ps-3'>Title (Ar)</th>
                          <th className='min-w-120px text-start'>Category</th>
                          <th className='min-w-120px text-start'>Section</th>
                          <th className='min-w-100px text-center'>Actions</th>
                        </tr>
                      </thead>

                      <tbody {...TableInstance.getTableBodyProps()}>
                        {activePage.length > 0 ? (
                          activePage.map((row) => {
                            TableInstance.prepareRow(row)
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <td className=' ' {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                                ))}
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td className='' colSpan={6}>
                              <div className='d-flex justify-content-center'>
                                <p className='text-dark default-cursor fs-6 mt-5'>
                                  {inputVal === '' ? (
                                    intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                                  ) : (
                                    <FormattedMessage
                                      id='MSG.NO_DATA_MATCH'
                                      values={{
                                        inputVal: <span className='text-primary'>{inputVal}</span>,
                                      }}
                                    />
                                  )}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {data.length > 10 && (
              <Pagination>
                <Pagination.First
                  onClick={() => gotoActivePage(0)}
                  disabled={!TableInstance.canPreviousPage}
                />
                <Pagination.Prev
                  onClick={TableInstance.previousPage}
                  disabled={!TableInstance.canPreviousPage}
                />
                {[...Array(activePageCount)].map((_, i) => (
                  <Pagination.Item
                    key={i}
                    active={i === activePageIndex}
                    onClick={() => gotoActivePage(i)}
                  >
                    {i + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={TableInstance.nextPage}
                  disabled={!TableInstance.canNextPage}
                />
                <Pagination.Last
                  onClick={() => gotoActivePage(activePageCount - 1)}
                  disabled={!TableInstance.canNextPage}
                />
                <div className='d-flex align-items-center mx-5'>
                  <span className='me-2'>Show</span>
                  <select
                    className='form-select form-select-solid fw-semibold w-75px me-2'
                    value={activePageSize}
                    onChange={(e) => setActivePageSize(Number(e.target.value))}
                  >
                    {pageOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </Pagination>
            )}
          </>
        ) : (
          <div className='card h-250px d-flex flex-column justify-content-center align-items-center'>
            <h3 className='fw-semibold'>No {sectionData?.name} found.</h3>
            <button
              className='btn btn-sm btn-light-primary me-3 fw-normal'
              onClick={() => setShowCrtModal(true)}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Create
            </button>
          </div>
        )}
      </>
    </>
  )
}
const ArticlesTableCustom = ({sectionId, sectionData}) => {
  const {id} = useParams()
  const navigate = useNavigate()
  const {execute, loading: isLoading} = usePostApi()
  const intl = useIntl()
  const [articlesData, setarticlesData] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [selectedArticle, setSelectedArticle] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const [modalType, setModalType] = useState('create')
  const [itemData, setItemData] = useState({})

  const getArticles = async (show) => {
    if (show) {
      setRefresh(true)
    }
    try {
      const url = `/articles/get/all`
      const formData = {
        section_id: sectionId,
      }

      const response = await execute(url, 'POST', formData)

      setarticlesData(response.data)

      setRefresh(false)
    } catch (error) {
      console.error('Error:', error)
      setRefresh(false)
    }
  }

  useEffect(() => {
    getArticles()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'SortRank',
        accessor: 'sort_rank',
        Cell: ({row}) => {
          return (
            <div className='ps-3'>
              <p className='text-dark fw-semibold text-hover-primary default-cursor fs-6 m-0'>
                {row.original.sort_rank}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'TitleEn',
        accessor: 'titleEn',
        Cell: ({row}) => {
          return (
            <p className='text-dark fw-normal text-hover-primary default-cursor fs-6 m-0'>
              {row.original.title_en || '-'}
            </p>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'TitleAr',
        accessor: 'TitleAr',
        Cell: ({row}) => {
          return (
            <div className='ps-3'>
              <p className='text-dark fw-semibold text-hover-primary default-cursor fs-6 m-0'>
                {row.original.title_ar}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Category',
        accessor: 'category',
        Cell: ({row}) => {
          return (
            <p className='text-dark fw-normal text-hover-primary default-cursor fs-6 m-0'>
              {row.original.category_name_ar}
              <br />
              {row.original?.category_name_en && `(${row.original.category_name_en})`}
            </p>
          )
        },

        disableSortBy: true,
      },

      {
        Header: 'Interviewed By',
        accessor: 'Interviewe',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-normal text-hover-primary d-block fs-6'>
                {row.original.section_name_ar}
                <br />
                {row.original?.section_name_en && `(${row.original.section_name_en})`}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          return (
            <div className='d-flex flex-row justify-content-center'>
              <div className='text-start'>
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm  me-1'
                  onClick={() => {
                    setItemData(row.original)
                    setModalType('edit')
                    setShowModal(true)
                  }}
                >
                  <KTIcon iconName='pencil' className='fs-3' />
                </button>
              </div>

              <Link
                to={`/${id}${sectionData?.path}/${row.original.article_id}`}
                className='btn btn-sm btn-light-primary'
              >
                View
              </Link>
            </div>
          )
        },
      },
    ],

    []
  )

  return (
    <>
      <DataTable
        columns={columns}
        data={articlesData ? articlesData : []}
        loading={isLoading}
        fetchData={getArticles}
        refresh={refresh}
        sectionId={sectionId}
        sectionData={sectionData}
      />
      <AddNewArticleCustom
        show={showModal}
        onClose={() => setShowModal(false)}
        fetchData={getArticles}
        secId={sectionId}
        type={modalType}
        data={itemData}
        timeStamp={new Date()}
      />
    </>
  )
}

export default ArticlesTableCustom
