import {useState, useEffect, useMemo} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../_metronic/helpers'
import clsx from 'clsx'
import usePostApi from './custom_hooks/usePostApi'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useThemeMode} from '../../_metronic/partials'
import {UseToast} from './useToast'
import {FormattedMessage, useIntl} from 'react-intl'
import AudioIcon from './media/components/audio/AudioFile.png'
import {CreateCategory} from '../pages/Category/CreateCategory'
import {CreateSubCategory} from '../pages/Category/CreateSubCategory'
import UploadAudio from './media/components/audio/UploadAudio'
import AddNewAttachment from './media/components/image/AddNewAttachment'
import UploadVideo from './media/components/video/UploadVideo'

function AddNewArticleCustom(props: any) {
  const {secId, type, data: artData, timeStamp} = props
  const {execute} = usePostApi()
  const handleClose = () => {
    props.onClose()
  }

  const [data, setData] = useState({
    title_ar: '',
    title_ar_phonetic: '',
    title_en: '',
    title_en_phonetic: '',
    section_id: secId,
    category_id: '',
    sub_category_id: '',
    content_ar: '',
    content_ar_phonetic: '',
    content_en: '',
    content_en_phonetic: '',
    media_type: 'I',
    media_id: '',
    voice_ar_type: 'TTS',
    voice_ar: '',
    voice_en_type: 'TTS',
    voice_en: '',
    status: true,
    start_ts: '',
    end_ts: '',
    tags_ar: '',
    tags_en: '',
    media_title_en: '',
    media_title_ar: '',
    media_url: '',
    sort_rank: 100,
  })

  useEffect(() => {
    if (type === 'edit') {
      setData({
        ...data,
        ...artData,
        status: artData.status === 0 ? false : true,
        media_type: artData.media_type === 'P' ? 'I' : artData.media_type,
        media_url: artData.media_info?.media_info,
      })
    }
  }, [type, timeStamp])

  const [currentStep, setCurrentStep] = useState<number>(0)
  const updateCurrentStep = (step: number) => {
    setCurrentStep(step)
  }

  function formatDateForDB(dateString: string) {
    const date = new Date(dateString)

    const pad = (num: any) => (num < 10 ? `0${num}` : num)
    const year = date.getFullYear()
    const month = pad(date.getMonth() + 1)
    const day = pad(date.getDate())
    const hours = pad(date.getHours())
    const minutes = pad(date.getMinutes())
    const seconds = pad(date.getSeconds())
    const milliseconds = String(date.getMilliseconds()).padStart(6, '0')

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`

    return formattedDate
  }

  const submitForm = async (data: any) => {
    const tags_en = data.tags_en ? data.tags_en.split(',') : []
    const tags_ar = data.tags_ar ? data.tags_ar.split(',') : []
    try {
      const url = type === 'edit' ? '/articles/update' : `/articles/create`

      const formData = {
        article_id: type === 'edit' ? data.article_id : undefined,
        title_ar: data.title_ar,
        title_ar_phonetic: data.title_ar_phonetic,
        title_en: data.title_en || '',
        title_en_phonetic: data.title_en_phonetic || '',
        section_id: data.section_id,
        category_id: data.category_id,
        sub_category_id: data.sub_category_id || null,
        content_ar: data.content_ar,
        content_ar_phonetic: data.content_ar_phonetic,
        content_en: data.content_en || '',
        content_en_phonetic: data.content_en_phonetic || '',
        media_type: data.media_type === 'I' ? 'P' : data.media_type,
        media_id: data.media_id,
        status: data.status ? 1 : 0,
        voice_ar_type: data.voice_ar_type,
        voice_ar: data.voice_ar_type === 'REC' ? data.voice_ar : '',
        voice_en_type: data.voice_en_type,
        voice_en: data.voice_en_type === 'REC' ? data.voice_en : '',
        start_ts: formatDateForDB(data.start_ts),
        end_ts: data.end_ts ? formatDateForDB(data.end_ts) : '',
        tags_en: data.tags_en,
        tags_ar: data.tags_ar,
        sort_rank: data.sort_rank,
      }

      const response = await execute(url, 'POST', formData)

      setData({
        title_ar: '',
        title_ar_phonetic: '',
        title_en: '',
        title_en_phonetic: '',
        section_id: secId,
        category_id: '',
        sub_category_id: '',
        content_ar: '',
        content_ar_phonetic: '',
        content_en: '',
        content_en_phonetic: '',
        media_type: '',
        media_id: '',
        voice_ar_type: '',
        voice_ar: '',
        voice_en_type: '',
        voice_en: '',
        status: true,
        start_ts: '',
        end_ts: '',
        tags_ar: '',
        tags_en: '',
        media_title_en: '',
        media_title_ar: '',
        media_url: '',
        sort_rank: 100,
      })

      props.fetchData()
      setCurrentStep(0)
      handleClose()

      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error('Error:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const nextStep = () => {
    if (currentStep === 0) {
      const element = document.getElementById('step1SubmitBtn')
      if (element) {
        element.click()
      }
    } else if (currentStep === 1) {
      const element = document.getElementById('step2Submit')
      if (element) {
        element.click()
      }
    } else if (currentStep === 2) {
      const element = document.getElementById('step3Submit')
      if (element) {
        element.click()
      }
    } else if (currentStep === 3) {
      const element = document.getElementById('step4Submit')
      if (element) {
        element.click()
      }
    }
  }

  const prevStep = () => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  const steps: any = [
    <Step1
      data={data}
      setData={setData}
      updateStep={updateCurrentStep}
      secId={secId}
      timeStamp={timeStamp}
    />,
    <Step2 data={data} setData={setData} updateStep={updateCurrentStep} />,
    <Step3 data={data} setData={setData} updateStep={updateCurrentStep} />,
    <Step4 data={data} setData={setData} updateStep={updateCurrentStep} submitForm={submitForm} />,
  ]

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
      backdrop='static' // Prevents closing when clicking outside
      // keyboard={false} // Prevents closing with the Escape key
      style={{background: '0,0,0,0.3'}}
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='d-flex justify-content-between mb-10'>
          <h4 className='fw-semibold'>{type === 'edit' ? 'Edit' : 'Create New'} Article</h4>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={handleClose}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <div className='w-100 row m-0'>
          <div className='col-lg-5 p-0'>
            <div className=''>
              <div className='d-flex'>
                <div
                  style={{borderRadius: '10px'}}
                  className={clsx(
                    'w-40px h-40px btn d-flex justify-content-center align-items-center fw-semibold',
                    {
                      'btn-primary': currentStep === 0,
                      'btn-light-primary': currentStep !== 0,
                    }
                  )}
                >
                  {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                </div>
                <div className='ms-5'>
                  <h6
                    className={clsx('mb-0 fw-semibold', {
                      'text-muted': currentStep >= 0,
                    })}
                  >
                    Step 1
                  </h6>
                  <p
                    className={clsx('fs-6 text-muted mb-0', {
                      'text-muted fw-semibold': currentStep >= 0,
                    })}
                  >
                    Article Info
                  </p>
                </div>
              </div>
              <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
              <div className='d-flex'>
                <div
                  style={{borderRadius: '10px'}}
                  className={clsx(
                    'w-40px h-40px btn d-flex justify-content-center align-items-center fw-semibold',
                    {
                      'btn-primary': currentStep === 1,
                      'btn-light-primary': currentStep !== 1,
                    }
                  )}
                >
                  {currentStep > 1 ? <i className='stepper-check fas fa-check'></i> : 2}
                </div>
                <div className='ms-5'>
                  <h6
                    className={clsx('mb-0 fw-semibold text-muted', {
                      'text-muted': currentStep >= 1,
                    })}
                  >
                    Step 2
                  </h6>
                  <p
                    className={clsx('fs-6 text-muted mb-0', {
                      'text-muted': currentStep >= 1,
                    })}
                  >
                    Title Info
                  </p>
                </div>
              </div>
              <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
              <div className='d-flex'>
                <div
                  style={{borderRadius: '10px'}}
                  className={clsx(
                    'w-40px h-40px btn d-flex justify-content-center align-items-center fw-semibold',
                    {
                      'btn-primary': currentStep === 2,
                      'btn-light-primary': currentStep !== 2,
                    }
                  )}
                >
                  {currentStep > 2 ? <i className='stepper-check fas fa-check'></i> : 3}
                </div>
                <div className='ms-5'>
                  <h6
                    className={clsx('mb-0 fw-semibold text-muted', {
                      'text-muted': currentStep >= 2,
                    })}
                  >
                    Step 3
                  </h6>
                  <p
                    className={clsx('fs-6 text-muted mb-0', {
                      'text-muted': currentStep >= 2,
                    })}
                  >
                    Content Info
                  </p>
                </div>
              </div>
              {/* Add more steps here */}
              <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>

              <div className='d-flex'>
                <div
                  style={{borderRadius: '10px'}}
                  className={clsx(
                    'w-40px h-40px btn d-flex justify-content-center align-items-center fw-semibold',
                    {
                      'btn-primary': currentStep === 3,
                      'btn-light-primary': currentStep !== 3,
                    }
                  )}
                >
                  {currentStep > 3 ? <i className='stepper-check fas fa-check'></i> : 4}
                </div>
                <div className='ms-5'>
                  <h6
                    className={clsx('mb-0 fw-semibold text-muted', {
                      'text-muted': currentStep >= 3,
                    })}
                  >
                    Step 4
                  </h6>
                  <p
                    className={clsx('fs-6 fw-semibold text-muted', {
                      'text-muted': currentStep > 3,
                    })}
                  >
                    Additional Info
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div>
        </div>

        <div className='w-100 d-flex flex-stack mt-5'>
          <div className='mr-2'>
            {currentStep > 0 && (
              <button onClick={prevStep} type='button' className='btn btn-sm btn-light me-3'>
                <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                Back
              </button>
            )}
          </div>

          <div>
            <button type='submit' className='btn btn-sm btn-light-primary' onClick={nextStep}>
              {currentStep !== steps.length - 1
                ? 'Continue'
                : `${type === 'edit' ? 'Update' : 'Create'} Article`}
              <KTIcon iconName='arrow-right' className='fs-3 ms-1' />
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const step1Schema = Yup.object().shape({
  category_id: Yup.string().required('Category is Required'),
  media_id: Yup.string().required('Media is Required'),
  media_type: Yup.string().required('Media type is Required'),
})

const Step1 = ({data, setData, updateStep, secId}: any) => {
  const {mode} = useThemeMode()
  const {execute} = usePostApi()
  const [sections, setSections] = useState([])
  const [categories, setCategories] = useState([])

  const [mediaType, setMediaType] = useState(data?.media_type)
  useEffect(() => {
    setMediaType(data.media_type)
  }, [data.media_type])

  const [mediaOptions, setMediaOptions] = useState([])

  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null)

  useEffect(() => {
    if (data.category_id) {
      setSelectedCategoryId(data.category_id)
    }
  }, [data.category_id])

  const [subCategories, setSubCategories] = useState([])
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false)
  const [showAddSubCategoryModal, setShowAddSubCategoryModal] = useState(false)

  //
  const [categoryMediaId, setCategoryMediaId] = useState<number | null>(null)
  //

  const fetchSections = async () => {
    try {
      const url = `/section/get/all`
      const formData = {}

      const response = await execute(url, 'POST', formData)
      setSections(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const fetchCategory = async (id: any) => {
    try {
      const url = `/category/section_id/get`
      const formData = {section_id: id}

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.category_id,
        label: `${each.category_name_ar} - ${each.category_name_en}`,
      }))
      setCategories(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const fetchSubCategory = async (id: any) => {
    try {
      const url = `/subcategory/get/category_id`
      const formData = {category_id: id}

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.sub_category_id,
        label: `${each.sub_category_name_ar} - ${each.sub_category_name_en}`,
      }))
      setSubCategories(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getMediatype = async () => {
    try {
      const url = `/media/active/get`
      const formData = {
        media_type: mediaType === 'P' ? 'I' : mediaType,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.media_id,
        label: `${each.media_title_ar} ${each.media_title_en ? `(${each.media_title_en})` : ' '}`,
        image: each.media_location_url,
        label_ar: each.media_title_ar,
        label_en: each.media_title_en,
      }))
      setMediaOptions(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    if (secId) {
      fetchCategory(secId)
    }
  }, [secId])

  useEffect(() => {
    fetchSections()
  }, [])

  useEffect(() => {
    if (selectedCategoryId) {
      fetchSubCategory(selectedCategoryId)
    }
  }, [selectedCategoryId])

  useEffect(() => {
    if (mediaType) {
      getMediatype()
    }
  }, [mediaType])

  const handleCategoryChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('category_id', selectedOption.value)
      setFieldValue('sub_category_id', null)
      setSubCategories([])
      setSelectedCategoryId(selectedOption.value) // Update state with selected category ID
      setCategoryMediaId(selectedOption.media_id)
    } else {
      setFieldValue('category_id', '')
      setSelectedCategoryId(null) // Clear category ID
      setCategoryMediaId(null)
    }
  }

  const handlesubcategoryChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('sub_category_id', selectedOption.value)
    } else {
      setFieldValue('sub_category_id', '') // Handle case where no option is selected
    }
  }

  const handleMediaChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('media_id', selectedOption.value)
    } else {
      setFieldValue('media_id', '') // Handle case where no option is selected
    }
  }

  const onSubmit = (values: any) => {
    setData({...data, ...values})
    // console.log('OnSubmit', values)
    updateStep(1)
  }

  const fetchCategoryWithCurrCategory = () => {
    if (secId) {
      fetchCategory(secId)
    } else return
  }

  const fetchSubCategoryWithCurrCategory = () => {
    if (selectedCategoryId) {
      fetchSubCategory(selectedCategoryId)
    } else return
  }

  const CustomOption = (props: any) => {
    const {data, innerRef, innerProps} = props
    return (
      <div ref={innerRef} {...innerProps} className='d-flex flex-row m-2'>
        <div className='d-flex'>
          <div className='symbol me-2'>
            <img src={data.image} alt='Brand Logo' style={{width: '35px', height: '35px'}} />
          </div>
        </div>
        <div className='ms-4 d-flex'>
          <div className='row'>
            <p className='text-dark text-hover-primary fs-8 mb-0'>{data.label_ar}</p>
            <p className='text-dark text-hover-primary fs-8 mt-0'>
              {data.label_en ? `(${data.label_en})` : ' '}
            </p>
          </div>
        </div>
      </div>
    )
  }

  const [showImageModal, setShowImageModal] = useState(false)
  const handleImageSelectFunc = (img: any, setFieldValue: any) => {
    setFieldValue('media_id', img.media_id)
    setFieldValue('media_title_en', img.media_title_en)
    setFieldValue('media_title_ar', img.media_title_ar)
    setFieldValue('media_url', img.media_location_url)
    setShowImageModal(false)
  }

  const [showAudioModal, setShowAudioModal] = useState(false)
  const handleAudioSelectFunc = (audio: any, setFieldValue: any) => {
    setFieldValue('media_id', audio.media_id)
    setFieldValue('media_title_en', audio.media_title_en)
    setFieldValue('media_title_ar', audio.media_title_ar)
    setFieldValue('media_url', audio.media_location_url)
    setShowAudioModal(false)
  }

  const [showVidModal, setShowVidModal] = useState(false)
  const handleVidSelectFunc = (video: any, setFieldValue: any) => {
    setFieldValue('media_id', video.media_id)
    setFieldValue('media_title_en', video.media_title_en)
    setFieldValue('media_title_ar', video.media_title_ar)
    setFieldValue('media_url', video.media_location_url)
    setShowVidModal(false)
  }

  const [audiosData, setAudiosData] = useState([])

  const getAudiosData = async () => {
    try {
      const url = `/media/active/get`
      const formData = {
        media_type: 'A',
      }
      const response = await execute(url, 'POST', formData)
      setAudiosData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getAudiosData()
  }, [])

  return (
    <>
      <CreateCategory
        className='modal-backdrop'
        showModal={showAddCategoryModal}
        setShowModal={setShowAddCategoryModal}
        id={{sectionId: secId}}
        categoryFetchData={fetchCategoryWithCurrCategory}
        closeModal={() => setShowAddCategoryModal(false)}
      />
      <CreateSubCategory
        className='modal-backdrop'
        showModal={showAddSubCategoryModal}
        setShowModal={setShowAddSubCategoryModal}
        subCategoryFetchData={fetchSubCategoryWithCurrCategory}
        id={{media_id: categoryMediaId, category_id: selectedCategoryId}}
      />
      <Formik
        validationSchema={step1Schema}
        initialValues={data}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({setFieldValue, values, handleChange}: any) => {
          console.log('step 1', values)
          return (
            <Form className=''>
              <ImageSelectModal
                showImgSelectModal={showImageModal}
                setShowImgSelectModal={setShowImageModal}
                handleImageSelect={handleImageSelectFunc}
                setFieldValue={setFieldValue}
              />

              <AudioSelectModal
                showAudioSelectModal={showAudioModal}
                setShowAudioSelectModal={setShowAudioModal}
                handleAudioSelect={handleAudioSelectFunc}
                setFieldValue={setFieldValue}
                audiosData={audiosData}
                getMediatype={getAudiosData}
              />

              <VideoSelectModal
                showVidSelectModal={showVidModal}
                setShowVidSelectModal={setShowVidModal}
                handleVidSelect={handleVidSelectFunc}
                setFieldValue={setFieldValue}
              />
              <div className='w-100 mb-5 me-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Section</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Select the section of the website or publication where this article will appear.'
                  ></i>
                </label>
                <Field
                  as='select'
                  name='section_id'
                  className='form-select form-select-solid'
                  value={secId}
                  disabled={sections.length}
                  onChange={(e: any) => {
                    setFieldValue('section_id', e.target.value)
                    setFieldValue('category_id', null)
                    setFieldValue('sub_category_id', null)
                    setCategories([])
                    setSubCategories([])
                    setSelectedCategoryId(null)
                  }}
                >
                  <option value={''}>Select Section</option>
                  {sections.map((each: any) => (
                    <option key={each.section_id} value={each.section_id}>
                      {each.section_name_ar} - {each.section_name_en}
                    </option>
                  ))}
                </Field>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='section_id' />
                </div>
              </div>

              <div className='d-flex flex-column flex-md-row mb-5'>
                <div className='w-100 w-md-50 me-md-2 mb-5 mb-md-0'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Category</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Choose a category that best fits the content of your article.'
                    ></i>
                  </label>
                  <div className='d-flex align-items-center'>
                    <div style={{width: '95%'}}>
                      <Select
                        value={categories.find(
                          (option: any) => option.value === values.category_id
                        )}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            color: '#ffffff',
                            borderWidth: '0px',
                          }),
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                          }),
                          singleValue: (baseStyles, state) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000',
                          }),
                          input: (baseStyles) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                          }),
                          option: (baseStyles, {isFocused, isSelected}) => ({
                            ...baseStyles,
                            backgroundColor: isSelected
                              ? '#C2C2C2'
                              : isFocused
                              ? '#e0e0e0'
                              : baseStyles.backgroundColor,
                            color: isSelected
                              ? '#000000'
                              : isFocused
                              ? '#000000'
                              : baseStyles.color,
                          }),
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          backgroundColor: '#000000',
                          colors: {
                            ...theme.colors,
                            primary25: 'rgba(80, 107, 236, 0.5)',
                            primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          },
                        })}
                        className='react-select-container me-1'
                        classNamePrefix='react-select'
                        options={categories}
                        isSearchable
                        placeholder='Select Category'
                        onChange={(val) => handleCategoryChange(setFieldValue, val)}
                      />
                    </div>
                    <button
                      type='button'
                      className='btn btn-icon btn-light btn-sm ms-1'
                      data-bs-toggle='tooltip'
                      onClick={() => setShowAddCategoryModal(true)}
                    >
                      <KTIcon iconName='plus' className='fs-2' />
                    </button>
                  </div>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='category_id' />
                  </div>
                </div>

                <div className='w-100 w-md-50 ms-md-2'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Subcategory</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Select a subcategory to further refine the topic of your article.'
                    ></i>
                  </label>
                  <div className='d-flex align-items-center'>
                    <div style={{width: '95%'}}>
                      <Select
                        value={subCategories?.find(
                          (option: any) => option.value === values.sub_category_id
                        )}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            color: '#ffffff',
                            borderWidth: '0px',
                          }),
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                          }),
                          singleValue: (baseStyles, state) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000',
                          }),
                          input: (baseStyles) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                          }),
                          option: (baseStyles, {isFocused, isSelected}) => ({
                            ...baseStyles,
                            backgroundColor: isSelected
                              ? '#C2C2C2'
                              : isFocused
                              ? '#e0e0e0'
                              : baseStyles.backgroundColor,
                            color: isSelected
                              ? '#000000'
                              : isFocused
                              ? '#000000'
                              : baseStyles.color,
                          }),
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          backgroundColor: '#000000',
                          colors: {
                            ...theme.colors,
                            primary25: 'rgba(80, 107, 236, 0.5)',
                            primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          },
                        })}
                        className='react-select-container me-1'
                        classNamePrefix='react-select'
                        options={subCategories}
                        isSearchable
                        placeholder='Select Subcategory'
                        onChange={(val) => handlesubcategoryChange(setFieldValue, val)}
                      />
                    </div>
                    <button
                      type='button'
                      className='btn btn-icon btn-light btn-sm ms-1'
                      data-bs-toggle='tooltip'
                      onClick={() => {
                        if (!selectedCategoryId) {
                          alert(
                            'Category not selected. Please select a category to create a subcategory.'
                          )
                        } else {
                          setShowAddSubCategoryModal(true)
                        }
                      }}
                    >
                      <KTIcon iconName='plus' className='fs-2' />
                    </button>
                  </div>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='sub_category_id' />
                  </div>
                </div>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Media Type</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Choose the type of media you want to associate with this article: audio, video, or image.'
                  ></i>
                </label>

                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    id='genderMale'
                    name='media_type'
                    value='A'
                    checked={values.media_type === 'A'} // Check if the value is 'M' (Male)
                    onChange={(event) => {
                      handleChange(event) // This updates the form state
                      setMediaType(event.target.value)
                      setFieldValue('media_type', 'A')
                      setFieldValue('media_id', null)
                    }}
                  />
                  <label className='form-check-label fs-6' htmlFor='genderMale'>
                    Audio
                  </label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    id='genderFemale'
                    name='media_type'
                    value='V'
                    checked={values.media_type === 'V'} // Check if the value is 'F' (Female)
                    onChange={(event) => {
                      handleChange(event) // This updates the form state
                      setMediaType(event.target.value)
                      setFieldValue('media_type', 'V')
                      setFieldValue('media_id', null)
                    }} // Handle change and update Formik state
                  />
                  <label className='form-check-label fs-6' htmlFor='genderFemale'>
                    Video
                  </label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    id='Images_type'
                    name='media_type'
                    value='I'
                    checked={values.media_type === 'I'} // Check if the value is 'O' (Others)
                    onChange={(event) => {
                      handleChange(event) // This updates the form state
                      setMediaType(event.target.value)
                      setFieldValue('media_type', 'I')
                      setFieldValue('media_id', null)
                    }} // Handle change and update Formik state
                  />
                  <label className='form-check-label fs-6' htmlFor='Images_type'>
                    Images
                  </label>
                </div>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='media_type' />
                </div>
              </div>

              <div className='d-none w-100'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Upload Media</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Upload the media file that corresponds to the selected media type (audio, video, or image).'
                  ></i>
                </label>

                <Select
                  value={mediaOptions.find((option: any) => option.value === values.media_id)}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                      color: '#ffffff',
                      borderWidth: '0px',
                    }),
                    menu: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000',
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                    }),
                    option: (baseStyles, {isFocused, isSelected}) => ({
                      ...baseStyles,
                      backgroundColor: isSelected
                        ? '#C2C2C2'
                        : isFocused
                        ? '#e0e0e0'
                        : baseStyles.backgroundColor,
                      color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    backgroundColor: '#000000',
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(80, 107, 236, 0.5)',
                      primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                    },
                  })}
                  className='react-select-container me-1'
                  classNamePrefix='react-select'
                  options={mediaOptions}
                  isSearchable
                  placeholder='Select Media'
                  onChange={(val) => handleMediaChange(setFieldValue, val)}
                  components={{
                    Option: CustomOption, // Custom option component
                  }}
                  // menuPortalTarget={document.body} // Attach the menu to the body to escape modal's z-index issues
                />

                <div className='text-danger mt-2'>
                  <ErrorMessage name='media_id' />
                </div>
              </div>

              {values.media_type === 'I' && (
                <div className='w-100 w-md-50 me-md-2 order-2 order-md-1'>
                  <label className='fs-6 fw-semibold form-label mb-2 required'>Upload Photo</label>
                  <div className='d-flex align-items-center'>
                    {values.media_url ? (
                      <img
                        src={values.media_url}
                        alt={values?.media_title_en || values.media_title_ar}
                        className='rounded me-5 cursor-pointer'
                        style={{width: '80px', height: '80px'}}
                        onClick={() => setShowImageModal(true)}
                      />
                    ) : (
                      <div
                        className='border border-3 border-secondary border-dashed d-flex align-items-center justify-content-center me-5 cursor-pointer rounded'
                        style={{width: '80px', height: '80px'}}
                        onClick={() => setShowImageModal(true)}
                      >
                        <KTIcon iconName='user' className='fs-2' />
                      </div>
                    )}
                    <button
                      type='button'
                      onClick={() => setShowImageModal(true)}
                      className='btn btn-light-primary btn-sm'
                    >
                      Select Image
                    </button>
                  </div>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='media_id' />
                  </div>
                </div>
              )}

              {values.media_type === 'A' && (
                <div className='mt-5'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Audio</span>
                  </label>

                  <div className='d-flex align-items-center'>
                    <button
                      type='button'
                      className='btn btn-light-primary btn-sm me-2'
                      onClick={() => setShowAudioModal(true)}
                    >
                      Select Audio
                    </button>
                    {values.media_id && values.media_url && (
                      <audio src={values.media_url} controls />
                    )}
                  </div>
                  <ErrorMessage name='media_id' component='div' className='text-danger mt-2' />
                </div>
              )}

              {values.media_type === 'V' && (
                <div className='mt-5'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Video</span>
                  </label>

                  <div className='d-flex flex-column align-items-start'>
                    <button
                      type='button'
                      className='btn btn-light-primary btn-sm me-2'
                      onClick={() => setShowVidModal(true)}
                    >
                      Select Video
                    </button>
                    {values.media_id && (
                      <span className='fw-semibold'>
                        Selected:{' '}
                        <span className='fw-normal'>
                          {values.media_title_en || values.media_title_ar}
                        </span>
                      </span>
                    )}
                  </div>
                  <ErrorMessage name='media_id' component='div' className='text-danger mt-2' />
                </div>
              )}

              <button
                className='d-none btn btn-light-primary btn-sm'
                type='submit'
                id='step1SubmitBtn'
              >
                Submit
              </button>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const step2Schema = Yup.object().shape({
  title_ar: Yup.string().required('Title (Arabic) is required'),
  title_ar_phonetic: Yup.string().required('Title phonetic (Arabic) is required'),
})

const Step2 = ({data, setData, updateStep}: any) => {
  const onSubmit = (values: any) => {
    setData({...data, ...values})
    updateStep(2)
  }
  return (
    <Formik initialValues={data} validationSchema={step2Schema} onSubmit={onSubmit}>
      {({setFieldValue, values, handleChange}) => {
        return (
          <Form className='w-100'>
            <div className='w-100 mb-5 me-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Title (Arabic)</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Enter the title of the article in Arabic.'
                ></i>
              </label>
              <Field
                type='text'
                name='title_ar'
                className='form-control form-control-solid'
                placeholder='أدخل العنوان'
                dir='rtl'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const {value} = e.target
                  setFieldValue('title_ar', value)

                  // Update title_ar_phonetic only if it's empty or hasn't been manually changed
                  if (!values.title_ar_phonetic || values.title_ar_phonetic === values.title_ar) {
                    setFieldValue('title_ar_phonetic', value)
                  }
                }}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='title_ar' />
              </div>
            </div>

            <div className='w-100 mb-5 me-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Title Phonetic (Arabic)</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Enter the phonetic transcription of the Arabic title.'
                ></i>
              </label>
              <Field
                name='title_ar_phonetic'
                className='form-control form-control-solid'
                placeholder='أدخل العنوان الصوتي'
                dir='rtl'
                type='text'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='title_ar_phonetic' />
              </div>
            </div>

            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className=''>Title (English)</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Enter the title of the article in English.'
                ></i>
              </label>
              <Field
                type='text'
                name='title_en'
                className='form-control form-control-solid'
                placeholder='Enter title'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const {value} = e.target
                  setFieldValue('title_en', value)
                  if (!values.title_en_phonetic || values.title_en_phonetic === values.title_en) {
                    setFieldValue('title_en_phonetic', value)
                  }
                }}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='title_en' />
              </div>
            </div>

            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className=''>Title Phonetic (English)</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Enter the phonetic transcription of the English title.'
                ></i>
              </label>
              <Field
                type='text'
                name='title_en_phonetic'
                className='form-control form-control-solid'
                placeholder='Enter title phonetic'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='title_en_phonetic' />
              </div>
            </div>

            <button type='submit' className='d-none btn btn-lg btn-primary w-100' id='step2Submit'>
              Submit
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

const step3Schema = Yup.object().shape({
  content_ar: Yup.string().required('Content (Arabic) is required'),
  content_ar_phonetic: Yup.string().required('Content phonetic (Arabic) is required'),
})

const Step3 = ({data, setData, updateStep}: any) => {
  const onSubmit = (values: any) => {
    setData({...data, ...values})
    updateStep(3)
  }

  return (
    <Formik initialValues={data} validationSchema={step3Schema} onSubmit={onSubmit}>
      {({setFieldValue, values}) => {
        return (
          <Form className='w-100'>
            <>
              <div className='w-100 mb-5 me-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Content (Arabic)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter the main content of the article in Arabic.'
                  ></i>
                </label>
                <Field
                  as='textarea'
                  type='text'
                  name='content_ar'
                  className='form-control form-control-solid'
                  placeholder='أدخل المحتوى'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const {value} = e.target
                    setFieldValue('content_ar', value)
                    if (
                      !values.content_ar_phonetic ||
                      values.content_ar_phonetic === values.content_ar
                    ) {
                      setFieldValue('content_ar_phonetic', value)
                    }
                  }}
                  dir='rtl'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='content_ar' />
                </div>
              </div>

              <div className='w-100 mb-5 me-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Content Phonetic (Arabic)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Provide the phonetic transcription of the Arabic content.'
                  ></i>
                </label>
                <Field
                  as='textarea'
                  type='text'
                  name='content_ar_phonetic'
                  className='form-control form-control-solid'
                  placeholder='أدخل المحتوى الصوتي'
                  onChange={(e: any) => {
                    // updateData(e.target.name, e.target.value)
                    setFieldValue('content_ar_phonetic', e.target.value)
                  }}
                  dir='rtl'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='content_ar_phonetic' />
                </div>
              </div>

              <div className='w-100 mb-5 ms-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className=''>Content (English)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter the main content of the article in English.'
                  ></i>
                </label>
                <Field
                  as='textarea'
                  type='text'
                  name='content_en'
                  className='form-control form-control-solid'
                  placeholder='Enter Content (en)'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const {value} = e.target
                    setFieldValue('content_en', value)
                    if (
                      !values.content_en_phonetic ||
                      values.content_en_phonetic === values.content_en
                    ) {
                      setFieldValue('content_en_phonetic', value)
                    }
                  }}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='content_en' />
                </div>
              </div>

              <div className='w-100 mb-5 ms-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className=''>Content Phonetic (English)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Provide the phonetic transcription of the English content.'
                  ></i>
                </label>
                <Field
                  as='textarea'
                  type='text'
                  name='content_en_phonetic'
                  className='form-control form-control-solid'
                  placeholder='Enter Content phonetic'
                  onChange={(e: any) => {
                    // updateData(e.target.name, e.target.value)
                    setFieldValue('content_en_phonetic', e.target.value)
                  }}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='content_en_phonetic' />
                </div>
              </div>

              <button className='d-none btn btn-light-primary' type='submit' id='step3Submit'>
                Submit
              </button>
            </>
          </Form>
        )
      }}
    </Formik>
  )
}

const step4Schema = Yup.object().shape({
  voice_ar_type: Yup.string().required('Voice type (Arabic) is Required'),
  start_ts: Yup.string().required('Start Date is Required'),
  end_ts: Yup.string()
    .nullable() // Allows end_ts to be null
    .test('is-greater', 'End Date must be greater than Start Date', function (value) {
      const {start_ts} = this.parent // Access the start_ts value
      if (!value) {
        // If end_ts is null or empty, it's valid
        return true
      }
      // Perform comparison if both dates are present
      return start_ts ? new Date(value) > new Date(start_ts) : true
    }),

  tags_ar: Yup.string().required('Tags (Arabic) is Required'),
  voice_ar: Yup.string().when('voice_ar_type', {
    is: (voice_ar_type: string) => voice_ar_type === 'REC',
    then: (schema) => schema.required('Record (Ar) is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  voice_en: Yup.string().when('voice_en_type', {
    is: (voice_en_type: string) => voice_en_type === 'REC',
    then: (schema) => schema.required('Record (En) is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  sort_rank: Yup.number().min(0, 'Value must be at least 0'),
})

const Step4 = ({data, setData, updateStep, submitForm}: any) => {
  const {mode} = useThemeMode()

  const {execute} = usePostApi()

  const [audiosData, setAudiosData] = useState([])

  const getMediatype = async () => {
    try {
      const url = `/media/active/get`
      const formData = {
        media_type: 'A',
      }
      const response = await execute(url, 'POST', formData)
      setAudiosData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getMediatype()
  }, [])

  const [showAudioSelectModal, setShowAudioSelectModal] = useState(false)
  const [selectedAudio, setSelectedAudio]: any = useState({})

  useEffect(() => {
    if (data.voice_ar && audiosData?.length > 0) {
      const audio = audiosData.find((each: any) => each.media_id === data.voice_ar)
      setSelectedAudio(audio)
    }
  }, [data.voice_ar, audiosData])

  const [showAudioSelectModalEn, setShowAudioSelectModalEn] = useState(false)
  const [selectedAudioEn, setSelectedAudioEn]: any = useState({})

  useEffect(() => {
    if (data.voice_en && audiosData?.length > 0) {
      const audio = audiosData.find((each: any) => each.media_id === data.voice_en)
      setSelectedAudioEn(audio)
    }
  }, [data.voice_en, audiosData])

  const [showAddModal, setShowAddModal] = useState(false)

  const onSubmit = (values: any) => {
    setData({...data, ...values})
    submitForm({...data, ...values})
  }

  const handleAudioSelect = (audio: any, setFieldValue: any) => {
    setFieldValue('voice_ar', audio.media_id)
    updateData('voice_ar', audio.media_id)
    setSelectedAudio(audio)
    setShowAudioSelectModal(false)
  }

  const handleAudioSelectEn = (audio: any, setFieldValue: any) => {
    setFieldValue('voice_en', audio.media_id)
    updateData('voice_en', audio.media_id)
    setSelectedAudioEn(audio)
    setShowAudioSelectModalEn(false)
  }

  const updateData = (name: string, val: any) => {
    setData((prev: any) => ({...prev, [name]: val}))
  }

  return (
    <Formik
      initialValues={{
        ...data,
        start_ts: data.start_ts ? data.start_ts : new Date(),
        tags_ar: data.tags_ar ? data.tags_ar : data.title_ar,
        tags_en: data.tags_en ? data.tags_en : data.title_en,
      }}
      validationSchema={step4Schema}
      onSubmit={onSubmit}
    >
      {({setFieldValue, values, handleChange}) => {
        return (
          <Form className='w-100'>
            <>
              <AudioSelectModal
                showAudioSelectModal={showAudioSelectModal}
                setShowAudioSelectModal={setShowAudioSelectModal}
                handleAudioSelect={handleAudioSelect}
                setFieldValue={setFieldValue}
                audiosData={audiosData}
                getMediatype={getMediatype}
              />
              <AudioSelectModal
                showAudioSelectModal={showAudioSelectModalEn}
                setShowAudioSelectModal={setShowAudioSelectModalEn}
                handleAudioSelect={handleAudioSelectEn}
                setFieldValue={setFieldValue}
                audiosData={audiosData}
                getMediatype={getMediatype}
              />

              <UploadAudio
                showModal={showAddModal}
                setShowModal={setShowAddModal}
                type='add'
                data={{}}
                loadData={() => {}}
              />
              <div className='d-flex flex-column flex-md-row'>
                <div className='w-100 mb-5 me-md-2'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Created Date</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Select the date when this article was created.'
                    ></i>
                  </label>
                  <Field name='start_ts'>
                    {({form, field}: any) => {
                      const {setFieldValue} = form
                      const {value} = field

                      const handleChange = (date: any) => {
                        setFieldValue('start_ts', date) // Set the date directly
                        // updateData('start_ts', date) // Use the correct key to update data
                      }

                      return (
                        <div
                          className={`react-datepicker-fullwidth-wrapper test ${
                            mode === 'dark' ? 'dark-mode' : 'light-mode'
                          }`}
                        >
                          <DatePicker
                            selected={new Date(value)}
                            minDate={new Date()}
                            showYearDropdown
                            scrollableYearDropdown
                            showMonthDropdown
                            useShortMonthInDropdown
                            showTimeSelect
                            timeFormat='HH:mm'
                            timeIntervals={30}
                            // dateFormat='dd/MM/yyyy h:mm aa'
                            dateFormat='dd/MM/yyyy HH:mm:ss' // 24-hour format
                            onChange={handleChange}
                            placeholderText='Select Date and Time'
                            className='form-control form-control-solid'
                            popperPlacement='bottom'
                            locale={'en'}
                          />
                        </div>
                      )
                    }}
                  </Field>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='start_ts' />
                  </div>
                </div>

                <div className='w-100 mb-5 ms-md-2'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span>End Date</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify the end date or expiration date for this article if applicable.'
                    ></i>
                  </label>
                  <div className='mb-4'>
                    <div className='text-start' style={{position: 'relative'}}>
                      <Field name='end_ts'>
                        {({form, field}: any) => {
                          const {setFieldValue} = form
                          const {value} = field

                          const handleChange = (date: any) => {
                            // Check if this is the first time the date is being selected
                            if (!value) {
                              // If no value is set yet, set the selected date's time to 23:59:59
                              const endOfDay = new Date(date)
                              endOfDay.setHours(23, 59, 59)
                              setFieldValue('end_ts', endOfDay)
                            } else {
                              // If a value is already set, let the user edit the date and time freely
                              setFieldValue('end_ts', date)
                            }
                          }

                          const currentDate = new Date()
                          const tomorrow = new Date(currentDate.setDate(new Date().getDate() + 1)) // Today's date + 1
                          const createdDate = new Date(form.values.start_ts) // Convert start_ts to a Date object

                          let minDate = null

                          if (form.values && form.values.start_ts) {
                            const ruleStartDate = new Date(form.values.start_ts)
                            minDate = ruleStartDate.setDate(ruleStartDate.getDate() + 1)
                          }

                          return (
                            <div
                              className={`react-datepicker-fullwidth-wrapper test ${
                                mode === 'dark' ? 'dark-mode' : 'light-mode'
                              }`}
                            >
                              <DatePicker
                                selected={value}
                                minDate={minDate || tomorrow}
                                showYearDropdown
                                scrollableYearDropdown
                                showMonthDropdown
                                useShortMonthInDropdown
                                showTimeSelect
                                timeFormat='HH:mm' // 24-hour format
                                timeIntervals={30}
                                dateFormat='dd/MM/yyyy HH:mm:ss' // 24-hour format
                                onChange={handleChange}
                                placeholderText='Select Date and Time'
                                className='form-control form-control-solid'
                                popperPlacement='bottom'
                                locale={'en'}
                              />
                            </div>
                          )
                        }}
                      </Field>

                      <span
                        className={`cursor-pointer ${values.end_ts ? '' : 'd-none'}`}
                        style={{
                          position: 'absolute',
                          top: '50%',
                          right: '0',
                          transform: 'translateY(-50%)',
                        }}
                        onClick={() => setFieldValue('end_ts', '')}
                      >
                        <KTIcon iconName='cross' className='fs-1 me-2 mt-1' />
                      </span>
                    </div>
                  </div>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='end_ts' />
                  </div>
                </div>
              </div>
              <div className='d-flex mb-5'>
                <div className='w-100 me-md-2'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Voice Type (Arabic)</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Choose how the Arabic content will be voiced: Text-to-Speech (TTS) or Recorded (REC).'
                    ></i>
                  </label>

                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='genderMale'
                      name='voice_ar_type'
                      value='TTS'
                      checked={values.voice_ar_type === 'TTS'} // Check if the value is 'M' (Male)
                      onChange={(e: any) => {
                        // updateData(e.target.name, e.target.value)
                        setFieldValue('voice_ar_type', e.target.value)
                      }} // // Handle change and update Formik state
                    />
                    <label className='form-check-label fs-6' htmlFor='genderMale'>
                      TTS
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='genderFemale'
                      name='voice_ar_type'
                      value='REC'
                      checked={values.voice_ar_type === 'REC'} // Check if the value is 'F' (Female)
                      onChange={(e: any) => {
                        // updateData(e.target.name, e.target.value)
                        setFieldValue('voice_ar_type', e.target.value)
                      }} // // Handle change and update Formik state
                    />
                    <label className='form-check-label fs-6' htmlFor='genderFemale'>
                      REC
                    </label>
                  </div>

                  <div className='text-danger mt-2'>
                    <ErrorMessage name='voice_ar_type' />
                  </div>
                </div>

                <div className='w-100 ms-md-2'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className=''>Voice Type (English)</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Choose how the English content will be voiced: Text-to-Speech (TTS) or Recorded (REC).'
                    ></i>
                  </label>

                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='genderMale'
                      name='voice_en_type'
                      value='TTS'
                      checked={values.voice_en_type === 'TTS'} // Check if the value is 'M' (Male)
                      onChange={(e: any) => {
                        // updateData(e.target.name, e.target.value)
                        setFieldValue('voice_en_type', e.target.value)
                      }} // Handle change and update Formik state
                    />
                    <label className='form-check-label fs-6' htmlFor='genderMale'>
                      TTS
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='genderFemale'
                      name='voice_en_type'
                      value='REC'
                      checked={values.voice_en_type === 'REC'} // Check if the value is 'F' (Female)
                      onChange={(e: any) => {
                        // updateData(e.target.name, e.target.value)
                        setFieldValue('voice_en_type', e.target.value)
                      }} // Handle change and update Formik state
                    />
                    <label className='form-check-label fs-6' htmlFor='genderFemale'>
                      REC
                    </label>
                  </div>

                  <div className='text-danger mt-2'>
                    <ErrorMessage name='media_type' />
                  </div>
                </div>
              </div>

              <div
                className={`w-100 d-flex align-items-start ${
                  values.voice_ar_type === 'TTS' || values.voice_en_type === 'TTS' ? 'my-5' : ''
                }`}
              >
                <div
                  className={`${
                    values.voice_ar_type === 'REC' && values.voice_en_type !== 'REC'
                      ? 'w-100'
                      : values.voice_ar_type !== 'REC'
                      ? 'd-none'
                      : 'w-50 me-md-2'
                  }`}
                >
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className=''>Upload Arabic Recording</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title="Upload the Arabic audio recording after selecting 'REC' as the voice type."
                    ></i>
                  </label>
                  <div className='d-flex align-items-center'>
                    <button
                      className='btn btn-light-primary btn-sm'
                      onClick={() => {
                        setShowAudioSelectModal(true)
                      }}
                      type='button'
                    >
                      <i className='las la-cloud-upload-alt fs-2'></i>Select from uploads
                    </button>
                    {/* <input type='file' className='form-control' /> */}
                    <button
                      type='button'
                      className='btn btn-icon btn-light btn-sm ms-2'
                      onClick={() => setShowAddModal(true)}
                    >
                      <KTIcon iconName='plus' className='fs-2' />
                    </button>
                  </div>
                  {selectedAudio && (selectedAudio.media_title_en || selectedAudio.media_title_ar) && (
                    <span className='fw-semibold'>
                      Selected:{' '}
                      <span className='fw-normal'>
                        {selectedAudio?.media_title_en || selectedAudio?.media_title_ar}
                      </span>
                    </span>
                  )}
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='voice_ar' />
                  </div>
                </div>
                <div
                  className={`${
                    values.voice_ar_type !== 'REC' && values.voice_en_type === 'REC'
                      ? 'w-100'
                      : values.voice_en_type !== 'REC'
                      ? 'd-none'
                      : 'w-50 ms-md-2'
                  }`}
                >
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className=''>Upload English Recording</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title="Upload the English audio recording after selecting 'REC' as the voice type."
                    ></i>
                  </label>
                  <div className='d-flex align-items-center'>
                    <button
                      className='btn btn-light-primary btn-sm'
                      onClick={() => {
                        setShowAudioSelectModalEn(true)
                      }}
                      type='button'
                    >
                      <i className='las la-cloud-upload-alt fs-2'></i>Select from uploads
                    </button>
                    <button
                      type='button'
                      className='btn btn-icon btn-light btn-sm ms-2'
                      onClick={() => {
                        setShowAddModal(true)
                      }}
                    >
                      <KTIcon iconName='plus' className='fs-2' />
                    </button>
                  </div>
                  {selectedAudioEn &&
                    (selectedAudioEn.media_title_en || selectedAudioEn.media_title_ar) && (
                      <span className='fw-semibold'>
                        Selected:{' '}
                        <span className='fw-normal'>
                          {selectedAudioEn?.media_title_en || selectedAudioEn?.media_title_ar}
                        </span>
                      </span>
                    )}
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='voice_en' />
                  </div>
                </div>
              </div>

              <div className='w-100 mb-5 mt-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className=''>Tags (Arabic)</span>
                  <i
                    className='fas fa-info-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter relevant tags or keywords for the article in Arabic.'
                  ></i>
                </label>
                <Field
                  id='tags'
                  type='text'
                  name='tags_ar'
                  className='form-control form-control-solid'
                  placeholder='أدخل العلامات مفصولة بفواصل'
                  dir='rtl'
                  onChange={(e: any) => {
                    // updateData(e.target.name, e.target.value)
                    setFieldValue('tags_ar', e.target.value)
                  }}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='tags_ar' />
                </div>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className=''>Tags (English)</span>
                  <i
                    className='fas fa-info-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter relevant tags or keywords for the article in English.'
                  ></i>
                </label>
                <Field
                  id='tags'
                  type='text'
                  name='tags_en'
                  className='form-control form-control-solid'
                  placeholder="Enter tags separated by comma's"
                  onChange={(e: any) => {
                    // updateData(e.target.name, e.target.value)
                    setFieldValue('tags_en', e.target.value)
                  }}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='tags_en' />
                </div>
              </div>

              <div className='d-flex'>
                <div className='w-50 me-2'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className=''>Status</span>
                    <i
                      className='fas fa-info-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Choose the current status of the policy (Active, Inactive).'
                    ></i>
                  </label>

                  <Field
                    id='sort_rank'
                    type='number'
                    name='sort_rank'
                    className='form-control form-control-solid'
                    placeholder='Enter sort rank'
                  />
                  <ErrorMessage component='div' className='text-danger' name='sort_rank' />
                </div>

                <div className='w-50 ms-2'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className=''>Status</span>
                    <i
                      className='fas fa-info-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Choose the current status of the policy (Active, Inactive).'
                    ></i>
                  </label>

                  <div className='form-floating flex-fill'>
                    <div className='form-check form-check-solid form-switch mb-0'>
                      <Field
                        className='form-check-input w-40px h-20px'
                        type='checkbox'
                        name='status'
                        id='toggle'
                        style={{
                          backgroundColor: values.status
                            ? '#009688'
                            : mode === 'dark'
                            ? '#1b1b29'
                            : '#f4f4f4',
                        }}
                      />
                      <span
                        className={`me-4 fw-semibold ${
                          values.status ? 'text-success' : 'text-danger'
                        }`}
                      >
                        {values.status ? 'Active' : 'Inactive'}
                      </span>
                      <ErrorMessage component='div' className='text-danger' name='status' />
                    </div>
                  </div>
                </div>
              </div>
              <button className='d-none btn btn-light-primary' type='submit' id='step4Submit'>
                Submit
              </button>
            </>
          </Form>
        )
      }}
    </Formik>
  )
}

const AudioSelectModal = ({
  showAudioSelectModal,
  setShowAudioSelectModal,
  handleAudioSelect,
  setFieldValue,
  audiosData,
  getMediatype: mediafetchData,
}: any) => {
  const {execute} = usePostApi()
  // const [audiosData, setAudiosData] = useState([])

  const [inputVal, setInputVal] = useState('')

  // const mediafetchData = async () => {
  //   try {
  //     const url = `/media/active/get`
  //     const formData = {media_type: 'A'}
  //     const response = await execute(url, 'POST', formData)
  //     setAudiosData(response.data)
  //   } catch (error) {
  //     console.error('Error:', error)
  //   }
  // }

  // useEffect(() => {
  //   mediafetchData()
  // }, [])

  const intl = useIntl()
  const [currentPage, setCurrentPage] = useState(1)
  const imagesPerPage = 6

  const filteredImages = useMemo(() => {
    return audiosData
      ? [...audiosData].filter((each: any) => {
          const titleAr =
            each.media_title_ar &&
            each.media_title_ar.toLowerCase().includes(inputVal.toLowerCase())
          const titleEn =
            each.media_title_en &&
            each.media_title_en.toLowerCase().includes(inputVal.toLowerCase())
          const descAr =
            each.media_desc_ar && each.media_desc_ar.toLowerCase().includes(inputVal.toLowerCase())
          const descEn =
            each.media_desc_en && each.media_desc_en.toLowerCase().includes(inputVal.toLowerCase())

          return titleAr || titleEn || descAr || descEn
        })
      : []
  }, [inputVal, audiosData])

  const totalPages = Math.ceil(filteredImages.length / imagesPerPage)

  const paginatedImages = filteredImages.slice(
    (currentPage - 1) * imagesPerPage,
    currentPage * imagesPerPage
  )

  const [showAddModal, setShowAddModal] = useState(false)

  return (
    <Modal
      show={showAudioSelectModal}
      onHide={() => setShowAudioSelectModal(false)}
      centered
      dialogClassName='custom-modal-audio-select modal-dialog-centered'
      contentClassName='custom-modal-content-img-select'
      style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
    >
      <Modal.Body className='overflow-auto w-100'>
        <UploadAudio
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          type='add'
          data={{}}
          loadData={mediafetchData}
          setMediaId={(audio: any) => {
            handleAudioSelect(audio, setFieldValue)
            setShowAddModal(false)
          }}
        />
        <div className='d-flex justify-content-between mb-10'>
          <h3 className='fs-3 fw-semibold'>Select Audio</h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => setShowAudioSelectModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <div className='d-flex mb-5'>
          <input
            type='text'
            className='form-control form-control-solid me-2'
            placeholder='Search audio'
            onChange={(e) => setInputVal(e.target.value)}
          />
          <button
            type='button'
            className='btn btn-light-primary ms-2'
            onClick={() => setShowAddModal(true)}
          >
            Upload
          </button>
        </div>

        <div className='d-flex flex-wrap'>
          {paginatedImages.length > 0 ? (
            paginatedImages.map((each: any, index: number) => (
              <div
                key={index}
                className='w-100 w-lg-50 d-flex align-items-center mb-5'
                onClick={() => {
                  handleAudioSelect(each, setFieldValue)
                }}
              >
                <img src={AudioIcon} alt='' className='w-75px w-lg-90px' />
                <div className='d-flex flex-column'>
                  <audio src={each.media_location_url} controls />
                  <span className='fs-6'>{each?.media_title_en || each?.media_title_ar}</span>
                </div>
              </div>
            ))
          ) : (
            <div className='d-flex justify-content-center w-100'>
              {inputVal === '' ? (
                intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
              ) : (
                <FormattedMessage
                  id='MSG.NO_DATA_MATCH'
                  values={{
                    inputVal: <span className='text-primary'>{inputVal}</span>,
                  }}
                />
              )}
            </div>
          )}
        </div>

        <div className='d-flex justify-content-between align-items-center mt-10'>
          <button
            onClick={() => setCurrentPage((prev: any) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className='btn btn-light btn-sm'
          >
            <KTIcon iconName='arrow-left' className='fs-3' />
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage((prev: any) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className='btn btn-light-primary btn-sm'
          >
            Next
            <KTIcon iconName='arrow-right' className='fs-3' />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const ImageSelectModal = ({
  showImgSelectModal,
  setShowImgSelectModal,
  handleImageSelect,
  setFieldValue,
}: any) => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const [inputVal, setInputVal] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const imagesPerPage = 18

  const [imagesData, setImagesData] = useState([])

  const getMediatype = async () => {
    try {
      const url = `/media/active/get`
      const formData = {
        media_type: 'I',
      }
      const response = await execute(url, 'POST', formData)
      setImagesData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getMediatype()
  }, [])

  const filteredImages = useMemo(() => {
    return imagesData
      ? [...imagesData].filter((each: any) => {
          const titleAr =
            each.media_title_ar &&
            each.media_title_ar.toLowerCase().includes(inputVal.toLowerCase())
          const titleEn =
            each.media_title_en &&
            each.media_title_en.toLowerCase().includes(inputVal.toLowerCase())
          const descAr =
            each.media_desc_ar && each.media_desc_ar.toLowerCase().includes(inputVal.toLowerCase())
          const descEn =
            each.media_desc_en && each.media_desc_en.toLowerCase().includes(inputVal.toLowerCase())

          return titleAr || titleEn || descAr || descEn
        })
      : []
  }, [inputVal, imagesData])

  const totalPages = Math.ceil(filteredImages.length / imagesPerPage) // Moved inside render function

  const paginatedImages = filteredImages.slice(
    (currentPage - 1) * imagesPerPage,
    currentPage * imagesPerPage
  )

  const [showAddImgModal, setShowAddImgModal] = useState(false)

  return (
    <Modal
      show={showImgSelectModal}
      onHide={() => setShowImgSelectModal(false)}
      centered
      dialogClassName='custom-modal-img-select modal-dialog-centered'
      contentClassName='custom-modal-content-img-select'
      style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
    >
      <Modal.Body className='overflow-auto w-100'>
        <AddNewAttachment
          showModal={showAddImgModal}
          setShowModal={setShowAddImgModal}
          type='add'
          data={{}}
          loadData={getMediatype}
          setMediaId={(img: any) => {
            setFieldValue('media_id', img.media_id)
            setFieldValue('media_url', img.media_location_url)
            setFieldValue('media_title_en', img.media_title_en)
            setFieldValue('media_title_en', img.media_title_ar)
            setShowAddImgModal(false)
            setShowImgSelectModal(false)
          }}
        />
        <div className='d-flex justify-content-between mb-10'>
          <h3 className='fs-3 fw-semibold'>Select Image</h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => setShowImgSelectModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <div className='d-flex mb-5'>
          <input
            type='text'
            className='form-control form-control-solid me-2'
            placeholder='Search image'
            onChange={(e) => setInputVal(e.target.value)}
          />
          <button
            className='btn btn-light-primary btn-sm ms-2'
            onClick={() => setShowAddImgModal(true)}
          >
            Upload
          </button>
        </div>

        <div className='d-flex flex-wrap gap-3'>
          {paginatedImages.length > 0 ? (
            paginatedImages.map((each: any) => (
              <div
                key={each.media_id}
                onClick={() => {
                  handleImageSelect(each, setFieldValue)
                }}
              >
                <img
                  src={each.media_location_url}
                  className='w-100px h-100px rounded'
                  alt={each.media_title_en || each.media_title_ar}
                />
              </div>
            ))
          ) : (
            <div className='d-flex justify-content-center w-100'>
              {inputVal === '' ? (
                intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
              ) : (
                <FormattedMessage
                  id='MSG.NO_DATA_MATCH'
                  values={{
                    inputVal: <span className='text-primary'>{inputVal}</span>,
                  }}
                />
              )}
            </div>
          )}
        </div>

        <div className='d-flex justify-content-between align-items-center mt-10'>
          <button
            onClick={() => setCurrentPage((prev: any) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className='btn btn-light btn-sm'
          >
            <KTIcon iconName='arrow-left' className='fs-3' />
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage((prev: any) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className='btn btn-light-primary btn-sm'
          >
            Next
            <KTIcon iconName='arrow-right' className='fs-3' />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const VideoSelectModal = ({
  showVidSelectModal,
  setShowVidSelectModal,
  handleVidSelect,
  setFieldValue,
}: any) => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const [inputVal, setInputVal] = useState('')

  const [currentPage, setCurrentPage] = useState(1)
  const imagesPerPage = 9

  const [videosData, setVideosData]: any = useState([])

  const getMediatype = async () => {
    try {
      const url = `/media/active/get`
      const formData = {
        media_type: 'V',
      }
      const response = await execute(url, 'POST', formData)
      setVideosData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getMediatype()
  }, [])

  const filteredImages = useMemo(() => {
    return videosData
      ? [...videosData].filter((each: any) => {
          const titleAr =
            each.media_title_ar &&
            each.media_title_ar.toLowerCase().includes(inputVal.toLowerCase())
          const titleEn =
            each.media_title_en &&
            each.media_title_en.toLowerCase().includes(inputVal.toLowerCase())
          const descAr =
            each.media_desc_ar && each.media_desc_ar.toLowerCase().includes(inputVal.toLowerCase())
          const descEn =
            each.media_desc_en && each.media_desc_en.toLowerCase().includes(inputVal.toLowerCase())

          return titleAr || titleEn || descAr || descEn
        })
      : []
  }, [inputVal, videosData])

  const totalPages = Math.ceil(filteredImages.length / imagesPerPage) // Moved inside render function

  const paginatedImages = filteredImages.slice(
    (currentPage - 1) * imagesPerPage,
    currentPage * imagesPerPage
  )

  const [showModal, setShowModal] = useState(false)
  const [selectedVideo, setSelectedVideo]: any = useState(null)

  const handleSelectVideo = (mediaItem: any) => {
    setSelectedVideo(mediaItem)
    setShowModal(true) // Show the modal when a video is selected
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setSelectedVideo(null)
  }
  const getYouTubeVideoId = (url: string) => {
    const videoIdMatch = url.match(
      /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.*v=))([\w-]{11})/
    )
    return videoIdMatch ? videoIdMatch[1] : null
  }

  const [showAddVidModal, setShowAddVidModal] = useState(false)

  return (
    <Modal
      show={showVidSelectModal}
      onHide={() => setShowVidSelectModal(false)}
      centered
      dialogClassName='custom-modal-audio-select modal-dialog-centered'
      contentClassName='custom-modal-content-img-select'
      style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
    >
      <Modal.Body className='overflow-auto w-100'>
        <UploadVideo
          showModal={showAddVidModal}
          setShowModal={setShowAddVidModal}
          type='add'
          data={{}}
          loadData={getMediatype}
          setMediaId={(vid: any) => {
            setFieldValue('media_id', vid.media_id)
            setFieldValue('media_url', vid.media_location_url)
            setFieldValue('media_title_en', vid.media_title_en)
            setFieldValue('media_title_en', vid.media_title_ar)
            setShowAddVidModal(false)
            setShowVidSelectModal(false)
          }}
        />
        <div className='d-flex justify-content-between mb-10'>
          <h3 className='fs-3 fw-semibold'>Select Video</h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => setShowVidSelectModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <div className='d-flex mb-5'>
          <input
            type='text'
            className='form-control form-control-solid me-2'
            placeholder='Search video'
            onChange={(e) => setInputVal(e.target.value)}
          />
          <button
            className='btn btn-light-primary btn-sm ms-2'
            onClick={() => setShowAddVidModal(true)}
          >
            Upload
          </button>
        </div>

        <div className='container'>
          <div className='row mb-0'>
            {paginatedImages && paginatedImages.length > 0 ? (
              <>
                {paginatedImages?.map((mediaItem: any) => {
                  const videoId = getYouTubeVideoId(mediaItem?.media_location_url)
                  return (
                    <div
                      key={mediaItem.media_id}
                      className={`col-6 col-md-4 h-200px mb-md-10${
                        selectedVideo?.media_id === mediaItem.media_id ? 'border-primary' : ''
                      }`}
                      style={{cursor: 'pointer'}}
                    >
                      <img
                        src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                        alt={mediaItem.media_title_en}
                        className='w-100'
                        onClick={() => handleVidSelect(mediaItem, setFieldValue)}
                      />
                      <div className='d-flex justify-content-between'>
                        <span className='fs-7'>{mediaItem.media_title_en}</span>
                        <span
                          className='text-primary ms-5'
                          onClick={() => handleSelectVideo(mediaItem)}
                        >
                          View
                        </span>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : (
              <div className='d-flex justify-content-center w-100'>
                {inputVal === '' ? (
                  intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                ) : (
                  <FormattedMessage
                    id='MSG.NO_DATA_MATCH'
                    values={{
                      inputVal: <span className='text-primary'>{inputVal}</span>,
                    }}
                  />
                )}
              </div>
            )}
          </div>
          {selectedVideo && (
            <VideoModal
              show={showModal}
              onHide={handleCloseModal}
              videoUrl={selectedVideo.media_location_url}
              title={selectedVideo.media_title_en}
            />
          )}
        </div>

        <div className='d-flex justify-content-between align-items-center mt-10'>
          <button
            onClick={() => setCurrentPage((prev: any) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className='btn btn-light btn-sm'
          >
            <KTIcon iconName='arrow-left' className='fs-3' />
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage((prev: any) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className='btn btn-light-primary btn-sm'
          >
            Next
            <KTIcon iconName='arrow-right' className='fs-3' />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const VideoModal = ({show, onHide, videoUrl, title}: any) => {
  const getVideoType = (url: string) => {
    if (!url) return
    // Check if the URL is a YouTube link (both short and long formats)
    if (url?.includes('youtube.com') || url?.includes('youtu.be')) {
      const videoId = url?.match(
        /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.*v=))([\w-]{11})/
      )
      return videoId ? {type: 'youtube', id: videoId[1]} : null
    }

    // Check if the URL is an mp4 link
    if (url?.endsWith('.mp4')) {
      return {type: 'mp4', url}
    }

    return null
  }

  let video: any = getVideoType(videoUrl)
  useEffect(() => {
    if (videoUrl) {
      video = getVideoType(videoUrl)
    }
  }, [videoUrl])

  return (
    <Modal show={show} onHide={onHide} centered size='lg'>
      <div className='d-flex justify-content-between mx-7 my-5'>
        <h3 className='fs-3 fw-semibold'>{title}</h3>
        <button type='button' className='d-block btn btn-sm btn-icon btn-light' onClick={onHide}>
          <KTIcon iconName='cross' className='fs-1' />
        </button>
      </div>
      <Modal.Body className='h-500px mt-0'>
        {video ? (
          video.type === 'youtube' ? (
            // YouTube video
            <iframe
              // width='660'
              // height='415'
              className='w-100 h-100'
              src={`https://www.youtube.com/embed/${video.id}`}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            ></iframe>
          ) : (
            // MP4 video
            <video className='w-100' height='415' controls>
              <source src={video.url} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          )
        ) : (
          <p>Invalid video URL</p>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default AddNewArticleCustom
