import React, {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTIcon} from '../../../_metronic/helpers'
import SearchIcon from './job-interview.png'
import TrackReqImg from './time-tracking.png'
import SearchLogo from './search.png'
import usePostApi from '../../modules/custom_hooks/usePostApi'
import {CreateSubCategory} from './CreateSubCategory'
import {EditSubCategory} from './EditSubCategory'
const DataTable = ({
  columns,
  data,
  loading,
  fetchData,
  category_name_en,
  category_id,
  media_id,
  category_name_ar,
}) => {
  const [inputVal, setInputVal] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [id, updateId] = useState('')
  const handleOpenModal = (subCategory) => {
    updateId(subCategory)
    setShowModal(true)
  }
  const intl = useIntl()
  const [currentStatus, setCurrentStatus] = useState(1)

  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const subCatnameAr = each.sub_category_name_ar
            ? each.sub_category_name_ar.toLowerCase().includes(inputVal.toLowerCase())
            : true
          const subCatnameEn = each.sub_category_name_en
            ? each.sub_category_name_en.toLowerCase().includes(inputVal.toLowerCase())
            : true
          const currentStatusMatches = each.status === currentStatus
          return (subCatnameAr || subCatnameEn) && currentStatusMatches
        })
      : []
  }, [data, inputVal, currentStatus])

  const [sortOrder, setSortOrder] = useState(0)

  const sortedData = useMemo(() => {
    if (sortOrder === 1) {
      return [...filteredData].sort((a, b) => a.sub_category_sort_rank - b.sub_category_sort_rank)
    } else if (sortOrder === -1) {
      return [...filteredData].sort((a, b) => b.sub_category_sort_rank - a.sub_category_sort_rank)
    }
    return filteredData
  }, [filteredData, sortOrder])

  const activeTableInstance = useTable({columns, data: sortedData}, usePagination)
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize

  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }

  const pageOptions = [5, 10, 20, 30, 40, 50]
  return (
    <div>
      {loading ? (
        <div className='mt-6 h-250px d-flex justify-content-center align-items-center'>
          <div className='spinner-border spinner-primary mr-15'></div>
        </div>
      ) : data.length > 0 ? (
        <div className='card p-10 py-5 pt-0'>
          <div className='card-header border-0 px-0'>
            <div className='d-flex flex-wrap flex-stack'>
              <div className='fw-bolder'>
                <ul className='nav'>
                  <li className='nav-item'>
                    <span
                      className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-success ${
                        currentStatus === 1 ? 'active' : ''
                      } fw-normal fs-6 px-4 me-1`}
                      data-bs-toggle='tab'
                      onClick={() => setCurrentStatus(1)} // Set status to active
                    >
                      Active
                    </span>
                  </li>
                  <li className='nav-item'>
                    <span
                      className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-danger ${
                        currentStatus === 0 ? 'active' : ''
                      } fw-normal fs-6 px-4 me-1`}
                      data-bs-toggle='tab'
                      onClick={() => setCurrentStatus(0)} // Set status to inactive
                    >
                      Inactive
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className='card-toolbar'>
              <div className='d-flex align-items-center position-relative me-4'>
                <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                <input
                  type='search'
                  id='kt_filter_search'
                  className='form-control form-control-white form-control-sm w-150px ps-9 fw-normal'
                  placeholder='Search'
                  value={inputVal}
                  onChange={(e) => setInputVal(e.target.value)}
                  autoComplete='off'
                />
              </div>
              <button
                className='btn btn-sm btn-light-primary fw-normal'
                onClick={(e) => {
                  e.stopPropagation()
                  handleOpenModal({category_id, category_name_en, media_id})
                }}
              >
                <KTIcon iconName='plus' className='fs-3' />
                Create Subcategory
              </button>
            </div>
          </div>
          <div className='tab-content'>
            <div className='tab-pane fade show active table-responsive'>
              <table
                className='table table-row-dashed align-middle table-row-gray-300'
                {...activeTableInstance.getTableProps()}
              >
                <thead>
                  <tr className='fw-semibold text-muted default-cursor bg-light rounded'>
                    <th className='min-w-150px default-cursor text-start ps-3'>
                      Sort Rank{' '}
                      <i
                        className='las la-sort-amount-down-alt fs-3 text-primary cursor-pointer'
                        data-bs-toggle='tooltip'
                        title='Sort in ascending order'
                        onClick={() => setSortOrder(sortOrder === 1 ? 0 : 1)}
                      ></i>
                      <i
                        className='las la-sort-amount-down text-primary fs-3 cursor-pointer'
                        data-bs-toggle='tooltip'
                        title='Sort in descending order'
                        onClick={() => setSortOrder(sortOrder === -1 ? 0 : -1)}
                      ></i>
                    </th>
                    <th className='min-w-120px'>Subcategory Name</th>
                    <th className='min-w-120px'>Description</th>
                    <th className='min-w-120px'>Status</th>
                    <th className='min-w-120px'>Action</th>
                  </tr>
                </thead>
                <tbody {...activeTableInstance.getTableBodyProps()}>
                  {activePage.length > 0 ? (
                    activePage.map((row) => {
                      activeTableInstance.prepareRow(row)
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td className=' ' {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          ))}
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td className='' colSpan={5}>
                        <div className='d-flex justify-content-center'>
                          <p className='text-dark default-cursor fs-6 mt-5'>
                            {inputVal === '' ? (
                              intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                            ) : (
                              <FormattedMessage
                                id='MSG.NO_DATA_MATCH'
                                values={{
                                  inputVal: <span className='text-primary'>{inputVal}</span>,
                                }}
                              />
                            )}
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {activePage.length > 10 && (
                <Pagination>
                  <Pagination.First
                    onClick={() => gotoActivePage(0)}
                    disabled={!activeTableInstance.canPreviousPage}
                  />
                  <Pagination.Prev
                    onClick={activeTableInstance.previousPage}
                    disabled={!activeTableInstance.canPreviousPage}
                  />
                  {[...Array(activePageCount)].map((_, i) => (
                    <Pagination.Item
                      key={i}
                      active={i === activePageIndex}
                      onClick={() => gotoActivePage(i)}
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={activeTableInstance.nextPage}
                    disabled={!activeTableInstance.canNextPage}
                  />
                  <Pagination.Last
                    onClick={() => gotoActivePage(activePageCount - 1)}
                    disabled={!activeTableInstance.canNextPage}
                  />
                  <div className='d-flex align-items-center mx-5'>
                    <span className='me-2'>Show</span>
                    <select
                      className='form-select form-select-solid fw-semibold w-75px me-2'
                      value={activePageSize}
                      onChange={(e) => setActivePageSize(Number(e.target.value))}
                    >
                      {pageOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </Pagination>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='m-2 p-4'>
          <h3 className='fw-semibold fs-3'>
            Managing{' '}
            {category_name_ar && category_name_en
              ? `${category_name_ar} (${category_name_en})`
              : category_name_ar || category_name_en || 'Category'}{' '}
            Page
          </h3>

          <p className='fw-normal'>
            Welcome to the Subcategory Page! Here, you have the ability to create new sub
            categories, inactive existing ones, and modify subcategory details as needed. We've
            designed this section to guide you through the process, ensuring that your sub
            categories are effectively managed and up to date.
          </p>
          <div className='row mt-6'>
            <div className='col-lg-4 mb-6'>
              <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
                <img
                  src={SearchIcon}
                  className='track-req-icon mb-5'
                  alt='Add Subcategory'
                  style={{width: '55px'}}
                />
                <p className='text-center'>
                  Click on the '+ Add Subcategory' button to create the sub categories.
                </p>
              </div>
            </div>
            <div className='col-lg-4 mb-6'>
              <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
                <img
                  src={TrackReqImg}
                  alt='Track Request'
                  className='track-req-icon mb-5'
                  style={{width: '55px'}}
                />
                <p className='text-center'>
                  Keep track of the status of your created sub categories. See what is active, and
                  inactives if needed.
                </p>
              </div>
            </div>
            <div className='col-lg-4 mb-6'>
              <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
                <img
                  src={SearchLogo}
                  className='track-req-icon mb-5'
                  alt='Search'
                  style={{width: '55px'}}
                />
                <p className='text-center'>
                  Use the search option to narrow down your search. You can search by name to find
                  the subcategory you need quickly.
                </p>
              </div>
            </div>
          </div>
          <div className='m-auto d-flex flex-column align-items-center'>
            <button
              className='btn btn-sm btn-light-primary fw-normal'
              onClick={(e) => {
                e.stopPropagation()
                handleOpenModal({category_id, category_name_en, media_id})
              }}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Create Subcategory
            </button>
          </div>
        </div>
      )}
      <CreateSubCategory
        className='modal-backdrop'
        showModal={showModal}
        setShowModal={setShowModal}
        subCategoryFetchData={fetchData}
        id={id}
      />
    </div>
  )
}

const SubCategory = ({props}) => {
  const {category_id, category_name_en, category_name_ar, media_id} = props
  const [showEditModal, setEditShowModal] = useState(false)
  const [id, updateId] = useState('')
  const [data, setTableData] = useState([])
  const {execute, loading: isLoading} = usePostApi()
  const fetchData = async () => {
    try {
      const url = `/subcategory/get/category_id`
      const formData = {category_id: category_id}
      const response = await execute(url, 'POST', formData)
      setTableData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category_id])
  const handleEditOpenModal = (category) => {
    updateId(category)
    setEditShowModal(true)
  }
  const handleCloseModal = () => {
    setEditShowModal(false)
  }
  const columns = useMemo(
    () => [
      {
        Header: 'SortRank',
        accessor: 'ruler_sort_rank',
        Cell: ({row}) => {
          return (
            <div className='ps-3 text-start'>
              <p className='text-dark fw-semibold text-hover-primary default-cursor fs-6 m-0'>
                {row.original.sub_category_sort_rank}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: 'sub_category_name',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <p className='fw-normal text-dark text-hover-primary fs-6 default-cursor ps-3'>
                  {row.original.sub_category_name_ar}{' '}
                  {row.original.sub_category_name_en
                    ? ` (${row.original.sub_category_name_en})`
                    : ''}
                </p>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Description',
        accessor: 'sub_category_description',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <p className='fw-normal text-dark text-hover-primary fs-6 default-cursor ps-3'>
                  {row.original.sub_category_desc_ar}{' '}
                  {row.original.sub_category_desc_en
                    ? ` (${row.original.sub_category_desc_en})`
                    : ''}
                </p>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row}) => {
          const statusColor = row.original.status === 1 ? 'success' : 'danger'
          return (
            <p className={`text-${statusColor} fw-normal d-block fs-6 default-cursor`}>
              {row.original.status === 1 ? 'Active' : 'Inactive'}
            </p>
          )
        },
      },
      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          return (
            <div
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={(e) => {
                e.stopPropagation()
                handleEditOpenModal({
                  ...row.original,
                })
              }}
            >
              <KTIcon iconName='pencil' className='fs-3' />
            </div>
          )
        },
      },
    ],
    [fetchData]
  )

  return (
    <>
      <DataTable
        columns={columns}
        data={data ? data : []}
        loading={isLoading}
        category_id={category_id}
        media_id={media_id}
        category_name_en={category_name_en}
        category_name_ar={category_name_ar}
        fetchData={fetchData}
      />
      <EditSubCategory
        showModal={showEditModal}
        setShowModal={setEditShowModal}
        subCategoryFetchData={fetchData}
        id={id}
        closeModal={handleCloseModal}
      />
    </>
  )
}

export default SubCategory
