import {useState, useMemo} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import {useIntl, FormattedMessage} from 'react-intl'
import AudioIcon from '../media/components/audio/AudioFile.png'
import UploadAudio from '../media/components/audio/UploadAudio'

const AudioSelectModal = ({
  showAudioSelectModal,
  setShowAudioSelectModal,
  audiosData,
  mediafetchData,
  handleAudioSelect,
}: any) => {
  const [inputVal, setInputVal] = useState('')
  const intl = useIntl()
  const [currentPage, setCurrentPage] = useState(1)
  const imagesPerPage = 6

  const filteredImages = useMemo(() => {
    return audiosData
      ? [...audiosData].filter((each: any) => {
          const titleAr =
            each.media_title_ar &&
            each.media_title_ar.toLowerCase().includes(inputVal.toLowerCase())
          const titleEn =
            each.media_title_en &&
            each.media_title_en.toLowerCase().includes(inputVal.toLowerCase())
          const descAr =
            each.media_desc_ar && each.media_desc_ar.toLowerCase().includes(inputVal.toLowerCase())
          const descEn =
            each.media_desc_en && each.media_desc_en.toLowerCase().includes(inputVal.toLowerCase())

          return titleAr || titleEn || descAr || descEn
        })
      : []
  }, [inputVal, audiosData])

  const totalPages = Math.ceil(filteredImages.length / imagesPerPage)

  const paginatedImages = filteredImages.slice(
    (currentPage - 1) * imagesPerPage,
    currentPage * imagesPerPage
  )

  const [showAddModal, setShowAddModal] = useState(false)
  return (
    <Modal
      show={showAudioSelectModal}
      onHide={() => setShowAudioSelectModal(false)}
      centered
      dialogClassName='custom-modal-audio-select modal-dialog-centered'
      contentClassName='custom-modal-content-img-select'
      style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
    >
      <Modal.Body className='overflow-auto w-100'>
        <UploadAudio
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          type='add'
          data={{}}
          loadData={mediafetchData}
          setMediaId={(audio: any) => {
            handleAudioSelect(audio)
            setShowAddModal(false)
          }}
        />
        <div className='d-flex justify-content-between mb-10'>
          <h3 className='fs-3 fw-semibold'>Select Audio</h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => setShowAudioSelectModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <div className='d-flex mb-5'>
          <input
            type='text'
            className='form-control form-control-solid me-2'
            placeholder='Search audio'
            onChange={(e) => setInputVal(e.target.value)}
          />
          <button
            type='button'
            className='btn btn-light-primary ms-2'
            onClick={() => setShowAddModal(true)}
          >
            Upload
          </button>
        </div>

        <div className='d-flex flex-wrap'>
          {paginatedImages.length > 0 ? (
            paginatedImages.map((each: any, index: number) => (
              <div
                key={index}
                className='w-100 w-lg-50 d-flex align-items-center mb-5'
                onClick={() => {
                  handleAudioSelect(each)
                }}
              >
                <img src={AudioIcon} alt='' className='w-75px w-lg-90px' />
                <div className='d-flex flex-column'>
                  <audio src={each.media_location_url} controls />
                  <span className='fs-6'>{each?.media_title_en || each?.media_title_ar}</span>
                </div>
              </div>
            ))
          ) : (
            <div className='d-flex justify-content-center w-100'>
              {inputVal === '' ? (
                intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
              ) : (
                <FormattedMessage
                  id='MSG.NO_DATA_MATCH'
                  values={{
                    inputVal: <span className='text-primary'>{inputVal}</span>,
                  }}
                />
              )}
            </div>
          )}
        </div>

        <div className='d-flex justify-content-between align-items-center mt-10'>
          <button
            onClick={() => setCurrentPage((prev: any) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className='btn btn-light btn-sm'
          >
            <KTIcon iconName='arrow-left' className='fs-3' />
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage((prev: any) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className='btn btn-light-primary btn-sm'
          >
            Next
            <KTIcon iconName='arrow-right' className='fs-3' />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AudioSelectModal
