import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FormikHelpers} from 'formik'
import usePostApi from '../../modules/custom_hooks/usePostApi'
import {KTIcon} from '../../../_metronic/helpers'
import Select from 'react-select'
import {useEffect, useState} from 'react'
import {useThemeMode} from '../../../_metronic/partials'
import {UseToast} from '../../modules/custom_hooks/useToast'
import {Modal} from 'react-bootstrap'
import AddNewAttachment from '../../modules/media/components/image/AddNewAttachment'
import UploadAudio from '../../modules/media/components/audio/UploadAudio'
import AudioFileIcon from '../../modules/media/components/audio/AudioFile.png'
import ImageSelectModal from '../../modules/helpers/ImageSelectModal'
import AudioSelectModal from '../../modules/helpers/AudioSelectModal'

const EditCategory = (props: any) => {
  const {category} = props.id || {}
  console.log(category)
  const {
    category_name_ar,
    category_name_en,
    category_desc_ar,
    category_desc_en,
    status,
    category_id,
    media_id,
    section_id,
    category_image,
    category_bgm,
    sort_rank,
  }: any = category || {}
  const {execute, postLoading} = usePostApi()
  const [showAddModal, setShowAddModal] = useState(false)
  const [audiosData, setAudiosData] = useState([])
  const [audioOptions, setAudioOptions] = useState([])
  const [showAudioModal, setShowAudioModal] = useState(false)
  const [mediaOptions, setMediaOptions] = useState([])
  const [mediaName, setMediaData]: any = useState([])
  const {mode} = useThemeMode()
  const validationSchema = Yup.object().shape({
    category_name_arabic: Yup.string().required('Category name is required'),
    category_description_arabic: Yup.string().required('Category description is required'),
    category_image: Yup.string().required('Category image is required'),
    sort_rank: Yup.number().min(0, 'Value must be at least 0'),
  })
  const mediafetchData = async () => {
    try {
      const url = `/media/active/get`
      const formData = {media_type: 'I'}
      const response = await execute(url, 'POST', formData)
      setMediaData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  const audiofetchData = async () => {
    try {
      const url = `/media/get/all`
      const formData = {media_type: 'A'}
      const response = await execute(url, 'POST', formData)
      setAudiosData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    mediafetchData()
    audiofetchData()
  }, [])
  useEffect(() => {
    const options: any = (mediaName || []).map((each: any) => ({
      value: each.media_location_url,
      media_id: each.media_id,
      label: `${each.media_title_ar} (${each.media_title_en})`,
      label_ar: each.media_title_ar,
      label_en: each.media_title_en,
    }))
    if (options) {
      setMediaOptions(options)
    }
  }, [mediaName])

  useEffect(() => {
    const options: any = (audiosData || []).map((each: any) => ({
      value: each.media_location_url,
      media_id: each.media_id,
      label: `${each.media_title_ar} ${each.media_title_en ? `(${each.media_title_en})` : ' '}`,
      label_ar: each.media_title_ar,
      media_location_url: each.media_location_url,
      label_en: each.media_title_en,
    }))
    if (options) {
      setAudioOptions(options)
    }
  }, [audiosData])

  const [imgSelectModal, setImgSelectModal] = useState(false)
  const [selectedImage, setSelectedImage]: any = useState(null)
  const handleImageSelect = (image: any, setFieldValue: any) => {
    setFieldValue('media_id', image.media_id)
    setFieldValue('category_image', image.media_location_url)
    setSelectedImage(image)
    setImgSelectModal(false)
  }

  const [showAudioSelectModal, setShowAudioSelectModal] = useState(false)
  const [selectedAudio, setSelectedAudio]: any = useState(null)
  const handleAudioSelectFunc = (audio: any, setFieldValue: any) => {
    if (audio) {
      setFieldValue('category_bgm', audio.media_location_url)
      setSelectedAudio(audio)
      setShowAudioSelectModal(false)
    } else {
      setFieldValue('category_bgm', '')
    }
  }

  const handleSubmit = async (values: any, {resetForm}: FormikHelpers<any>) => {
    const url = `/category/update`
    try {
      const requestData = {
        section_id: section_id,
        category_id: category_id,
        media_id: values.media_id,
        category_name_ar: values.category_name_arabic,
        category_name_en: values.category_name_english,
        category_bgm: values.category_bgm,
        category_desc_ar: values.category_description_arabic,
        category_desc_en: values.category_description_english,
        category_icon: 'inventory',
        category_image: values.category_image,
        status: values.status ? 1 : 0,
        sort_rank: values.sort_rank,
      }
      const response = await execute(url, 'POST', requestData, 'post')
      resetForm()
      setSelectedAudio(null)
      setSelectedImage(null)
      props.categoryFetchData()
      props.setShowModal(false)
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }
  const handleCancel = (resetForm: any) => {
    resetForm()
  }

  return (
    <div>
      <Modal
        show={props.showModal}
        onHide={() => props.setShowModal(false)}
        centered
        dialogClassName='custom-modal modal-dialog-centered'
        contentClassName='custom-modal-content'
        style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
      >
        <Modal.Body className='overflow-auto'>
          <div className='card py-0 overflow-auto'>
            <div className='w-100 m-auto'>
              <div className='d-flex justify-content-between mb-6'>
                <h4 className='fw-semibold'>Edit Category</h4>
                <button
                  type='button'
                  className='d-block btn btn-sm btn-icon btn-light'
                  onClick={() => {
                    props.setShowModal(false)
                  }}
                >
                  <KTIcon iconName='cross' className='fs-1' />
                </button>
              </div>
              <Formik
                initialValues={{
                  category_name_arabic: category_name_ar || '',
                  category_name_english: category_name_en || '',
                  category_description_arabic: category_desc_ar || '',
                  category_description_english: category_desc_en || '',
                  category_image: category_image || '',
                  category_bgm: category_bgm || '',
                  media_id: media_id || '',
                  status: status === 1,
                  sort_rank: sort_rank,
                }}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={handleSubmit}
              >
                {({values, resetForm, setFieldValue}) => {
                  return (
                    <Form>
                      <ImageSelectModal
                        showModal={imgSelectModal}
                        setShowModal={setImgSelectModal}
                        loading={false}
                        imagesData={mediaName}
                        handleImageSelect={(image: any) => handleImageSelect(image, setFieldValue)}
                      />

                      <AudioSelectModal
                        showAudioSelectModal={showAudioSelectModal}
                        setShowAudioSelectModal={setShowAudioSelectModal}
                        audiosData={audiosData}
                        handleAudioSelect={(audio: any) =>
                          handleAudioSelectFunc(audio, setFieldValue)
                        }
                      />
                      <div className='d-flex flex-column flex-md-row justify-content-between'>
                        <div className='w-100 w-md-50 me-md-2 mb-2'>
                          <label
                            htmlFor='floatingInput'
                            className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'
                          >
                            Category Name (Arabic)
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-solid mb-1'
                            id='floatingInput'
                            placeholder='Enter Category Name'
                            name='category_name_arabic'
                            dir='rtl'
                          />
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='category_name_arabic'
                          />
                        </div>
                        <div className='w-100 w-md-50 ms-md-2 mb-2'>
                          <label
                            htmlFor='floatingInput'
                            className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'
                          >
                            Category Name (English)
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-solid mb-1'
                            id='floatingInput'
                            placeholder='Enter Category Name'
                            name='category_name_english'
                          />
                        </div>
                      </div>
                      <div className='d-flex flex-column flex-md-row justify-content-between'>
                        <div className='w-100 w-md-50 me-md-2 mb-2'>
                          <label
                            htmlFor='floatingInput'
                            className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'
                          >
                            Category Description (Arabic)
                          </label>
                          <Field
                            id='brand_des_arabic'
                            dir='rtl'
                            as='textarea'
                            className={`form-control form-control form-control-solid`}
                            name='category_description_arabic'
                            placeholder='Briefly describe your Category'
                            rows={3}
                          />
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='category_description_arabic'
                          />
                        </div>
                        <div className='w-100 w-md-50 ms-md-2 mb-2'>
                          <label
                            htmlFor='floatingInput'
                            className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'
                          >
                            Category Description (English)
                          </label>
                          <Field
                            id='brand_des_english'
                            as='textarea'
                            className={`form-control form-control form-control-solid`}
                            name='category_description_english'
                            placeholder='Briefly describe your Category'
                            rows={3}
                          />
                        </div>
                      </div>
                      <div className='d-flex flex-column flex-md-row justify-content-between'>
                        <div className='w-100 w-md-50 me-md-2 mb-4'>
                          <label className='fs-6 fw-semibold form-label mb-2 required'>
                            Category Image
                          </label>
                          <div className='d-flex align-items-center'>
                            {selectedImage || category_image ? (
                              <img
                                src={selectedImage?.media_location_url || category_image}
                                alt={selectedImage?.media_title_en || selectedImage?.media_title_en}
                                className='h-80px w-80px rounded me-5 cursor-pointer'
                                onClick={() => setImgSelectModal(true)}
                              />
                            ) : (
                              <div
                                className='cursor-pointer h-80px w-80px border border-3 border-secondary border-dashed d-flex align-items-center justify-content-center me-5 rounded'
                                onClick={() => setImgSelectModal(true)}
                              >
                                <KTIcon iconName='user' className='fs-2' />
                              </div>
                            )}
                            <button
                              type='button'
                              onClick={() => setImgSelectModal(true)}
                              className='btn btn-light-primary btn-sm'
                            >
                              Select Image
                            </button>
                          </div>
                          <ErrorMessage
                            component='div'
                            className='text-danger mt-2'
                            name='category_image'
                          />
                        </div>

                        <div className='w-100 w-md-50 ms-md-2 mb-4'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                            <span className=''>Category Audio</span>
                            <i
                              className='fas fa-exclamation-circle ms-2 fs-7'
                              data-bs-toggle='tooltip'
                              title="Upload the Arabic audio recording after selecting 'REC' as the voice type."
                            ></i>
                          </label>
                          <div className='d-flex align-items-center'>
                            <button
                              className='btn btn-light-primary btn-sm'
                              onClick={() => {
                                setShowAudioSelectModal(true)
                              }}
                              type='button'
                            >
                              <i className='las la-cloud-upload-alt fs-2'></i>Select from uploads
                            </button>
                          </div>
                          {/* 
                          {selectedAudio &&
                            (selectedAudio.media_title_en || selectedAudio.media_title_ar) && (
                              <span className='fw-semibold'>
                                Selected:{' '}
                                <span className='fw-normal'>
                                  {selectedAudio?.media_title_en || selectedAudio?.media_title_ar}
                                </span>
                              </span>
                            )} */}
                          {selectedAudio?.media_location_url || category_bgm ? (
                            <audio
                              src={selectedAudio?.media_location_url || category_bgm}
                              controls
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className='d-flex flex-column flex-md-row justify-content-between'>
                        <div className='w-100 w-md-50 me-md-2'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                            <span className='required'>Sort rank</span>
                            <i
                              className='fas fa-info-circle ms-2 fs-7'
                              data-bs-toggle='tooltip'
                              title='Please enter the attachment title in Arabic.'
                            ></i>
                          </label>
                          <Field
                            id='sort_rank'
                            type='number'
                            name='sort_rank'
                            className='form-control form-control-solid'
                            placeholder='Enter sort rank'
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='sort_rank' />
                          </div>
                        </div>

                        <div className='w-100 w-md-50 ms-md-2'>
                          <label className='fs-6 fw-semibold form-label mb-2'>Status</label>
                          <div className='form-floating flex-fill'>
                            <div className='form-check form-check-solid form-switch'>
                              <Field
                                className='form-check-input w-40px h-20px'
                                type='checkbox'
                                name='status'
                                id='toggle'
                                style={{
                                  backgroundColor: values.status
                                    ? '#009688'
                                    : mode === 'dark'
                                    ? '#1b1b29'
                                    : '#f4f4f4',
                                }}
                              />
                              <span
                                className={`me-4 fw-semibold ${
                                  values.status ? 'text-success' : 'text-danger'
                                }`}
                              >
                                {values.status ? 'Active' : 'Inactive'}
                              </span>
                              <ErrorMessage component='div' className='text-danger' name='status' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <button
                          type='reset'
                          onClick={() => {
                            handleCancel(resetForm)
                            props.setShowModal(false)
                          }}
                          className='btn btn-sm btn-light me-2'
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-sm btn-light-primary'
                          data-kt-menu-dismiss='true'
                        >
                          {postLoading ? (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          ) : (
                            <>
                              <KTIcon iconName='check' className='fs-7' />
                              Save
                            </>
                          )}
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <AddNewAttachment
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        type='add'
        data={{}}
        loadData={mediafetchData}
      />
      <UploadAudio
        showModal={showAudioModal}
        setShowModal={setShowAudioModal}
        type='add'
        data={{}}
        loadData={audiofetchData}
      />
    </div>
  )
}

export {EditCategory}
