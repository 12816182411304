import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import ArticlesRouting from '../modules/articles/ArticlesRouting'
import Category from '../pages/Category/Category'
// import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
// import MediaRoute from '../modules/media/MediaRoute'
// import AdministrationRoute from '../modules/administration/AdministrationRoute'
// import QuizRoute from '../modules/quiz/QuizRoute'
// import PuzzleRoute from '../modules/puzzles/PuzzleRoute'

const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const PuzzleRoute = lazy(() => import('../modules/puzzles/PuzzleRoute'))
  const RulersMessageRoute = lazy(() => import('../modules/rulers_message/RulersMsgRoute'))
  const MediaRoute = lazy(() => import('../modules/media/MediaRoute'))
  const ProverbsRouting = lazy(() => import('../modules/proverbs/ProverbsRouting'))
  const QuizRoute = lazy(() => import('../modules/quiz/QuizRoute'))
  const JobsRoute = lazy(() => import('../modules/jobs/JobsRoute'))
  const EventsRoute = lazy(() => import('../modules/evnts/EventsRoute'))
  const PodcastsRoute = lazy(() => import('../modules/podcasts/PodcastsRoute'))
  const DictionaryRoute = lazy(() => import('../modules/dictionary/DictionaryRoute'))
  // const CreateArticleFullPage = lazy(
  //   () => import('../modules/articles/add_article/CreateArticleFP')
  // )
  const LocationsRoute = lazy(() => import('../modules/locations/LocationRoute'))
  const FeedbackRoute = lazy(() => import('../modules/feedback/FeedbackRoute'))
  const CmsRoute = lazy(() => import('../modules/cms_pages/CmsMain'))
  const AdminRoute = lazy(() => import('../modules/administration/AdminRoute'))
  const Awards = lazy(() => import('../modules/awards/AwardsRoute'))

  const searchParams = new URLSearchParams(window.location.search)
  const redirectParam: string = decodeURIComponent(searchParams?.get('redirect') ?? '')

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* <Route path='auth/*' element={<Navigate to='/dashboard' />} /> */}
        <Route path='auth/*' element={<Navigate to={redirectParam || '/dashboard'} />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}
        {/* Lazy Modules */}
        <Route
          path='/media/*'
          element={
            <SuspensedView>
              <MediaRoute />
            </SuspensedView>
          }
        />
        <Route
          path='/puzzles/*'
          element={
            <SuspensedView>
              <PuzzleRoute />
            </SuspensedView>
          }
        />
        <Route
          path={`/quiz/*`}
          element={
            <SuspensedView>
              <QuizRoute />
            </SuspensedView>
          }
        />
        <Route
          path='/rulers-message/*'
          element={
            <SuspensedView>
              <RulersMessageRoute />
            </SuspensedView>
          }
        />
        <Route
          path='/:id/communities/*'
          element={
            <SuspensedView>
              <ArticlesRouting />
            </SuspensedView>
          }
        />
        <Route
          path='/:id/proverbs/*'
          element={
            <SuspensedView>
              <ProverbsRouting />
            </SuspensedView>
          }
        />
        <Route
          path='/:id/jobs/*'
          element={
            <SuspensedView>
              <JobsRoute />
            </SuspensedView>
          }
        />
        <Route
          path='/:id/podcasts/*'
          element={
            <SuspensedView>
              <PodcastsRoute />
            </SuspensedView>
          }
        />
        <Route
          path='/events/*'
          element={
            <SuspensedView>
              <EventsRoute />
            </SuspensedView>
          }
        />
        <Route
          path='/dictionary/*'
          element={
            <SuspensedView>
              <DictionaryRoute />
            </SuspensedView>
          }
        />
        <Route
          path='/locations/*'
          element={
            <SuspensedView>
              <LocationsRoute />
            </SuspensedView>
          }
        />
        <Route
          path='/feedback/*'
          element={
            <SuspensedView>
              <FeedbackRoute />
            </SuspensedView>
          }
        />
        <Route
          path='/cms-pages/*'
          element={
            <SuspensedView>
              <CmsRoute />
            </SuspensedView>
          }
        />
        <Route
          path='/administration/*'
          element={
            <SuspensedView>
              <AdminRoute />
            </SuspensedView>
          }
        />
        <Route
          path='/awards/*'
          element={
            <SuspensedView>
              <Awards />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={<SuspensedView>{/* <ProfilePage /> */}</SuspensedView>}
        />
        <Route
          path='crafted/pages/wizards/*'
          element={<SuspensedView>{/* <WizardsPage /> */}</SuspensedView>}
        />
        <Route
          path='crafted/widgets/*'
          element={<SuspensedView>{/* <WidgetsPage /> */}</SuspensedView>}
        />
        <Route
          path='crafted/account/*'
          element={<SuspensedView>{/* <AccountPage /> */}</SuspensedView>}
        />
        <Route path='apps/chat/*' element={<SuspensedView>{/* <ChatPage /> */}</SuspensedView>} />
        <Route
          path='apps/user-management/*'
          element={<SuspensedView>{/* <UsersPage /> */}</SuspensedView>}
        />
        {/* Page Not Found */}
        <Route
          path={`:id/:module/category-subcategory`}
          element={
            <SuspensedView>
              <Category />
            </SuspensedView>
          }
        />
        <Route
          path='*'
          element={
            <div>
              <h3 className='fs-3 fw-semibold'>Under Progress</h3>
            </div>
          }
        />

        {/* <Route
          path={`:id/:module/create-article`}
          element={
            <SuspensedView>
              <CreateArticleFullPage />
            </SuspensedView>
          }
        /> */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
