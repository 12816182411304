import {Route, Routes} from 'react-router-dom'
import CreateArticleFullPage from '../CustomArticleCrtFP'
import ArticlesTableCustom from '../ArticlesTableCustom'
import ViewArtcle from '../ArticleView'

function ArticlesRouting() {
  return (
    <Routes>
      <Route
        path='/manage-topics'
        element={
          <ArticlesTableCustom
            sectionId={1}
            sectionData={{
              title: 'Articles',
              name: 'articles',
              path: '/communities/manage-topics',
              crtPath: '/1/communities/create',
            }}
          />
        }
      />
      <Route
        path='/:type'
        element={<CreateArticleFullPage returnPath={'/1/communities/manage-topics'} secId={1} />}
      />
      <Route
        path='/manage-topics/:art_id'
        element={<ViewArtcle returnPath={'/1/communities/manage-topics'} />}
      />

      <Route
        index
        element={
          <ArticlesTableCustom
            sectionId={1}
            sectionData={{
              title: 'Articles',
              name: 'articles',
              path: '/communities/manage-topics',
              crtPath: '/1/communities/create',
            }}
          />
        }
      />
    </Routes>
  )
}

export default ArticlesRouting
