import React from 'react'
import {KTIcon} from '../../../_metronic/helpers'
const InfoCategory = (props: any) => {
  const {category, ClosePopover} = props.props
  return (
    <div className='card'>
      <div className='card-header'>
        <h3 className='card-title fw-semibold fs-3 text-dark'>Category Information</h3>
        <div className='card-toolbar me-2'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-active-light me-n5'
            onClick={() => ClosePopover()}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>
      </div>
      <div className='px-7 py-5' style={{maxHeight: '500px', overflowY: 'auto'}}>
        <div className='modal-body position-relative '>
          <div className='d-flex flex-row'>
            <div className='d-flex'>
              <div className='symbol symbol-50px me-2'>
                <img src={category.category_image} alt='Brand Logo' />
              </div>
            </div>
            <div className='ms-4 d-flex'>
              <div className='row'>
                <h1 className='text-dark fw-semibold text-hover-primary fs-6'>
                  {category.category_name_ar}
                  {category.category_name_en ? ` (${category.category_name_en})` : ''}
                </h1>
                <p className='text-dark text-hover-primary fs-8'>
                  {category.category_desc_ar}
                  {category.category_desc_en ? ` (${category.category_desc_en})` : ''}
                </p>
              </div>
            </div>
          </div>
          {category.category_bgm && (
            <div className='d-flex justify-content-between align-items-center'>
              <label
                htmlFor='floatingInput'
                className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'
              >
                Music
              </label>
              <audio src={category.category_bgm} controls />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export {InfoCategory}
